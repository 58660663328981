import styles from './connection-dialog.module.css';
import {Button, Dialog, DialogContent, Divider} from '@mui/material';
import Connection from '../connection/connection';
import metaMask from '../../images/meta-mask.svg';
import walletConnect from '../../images/wallet-connect.svg';
import {useWeb3React} from '@web3-react/core';
import config from '../../config/config';

function ConnectionDialog(props: {
    open: boolean;
    handleConnectionDialogClose: any;
}) {
    const {deactivate} = useWeb3React();

    return (
        <div className={`${styles.container}`}>
            <Dialog open={props.open} onClose={props.handleConnectionDialogClose}>
                <DialogContent>
                    <div className={`${styles.metaMaskConnection}`}>
                        <Connection
                            onConnect={props.handleConnectionDialogClose}
                            connector={config.connectors.Injected}
                            icon={metaMask}
                            text="Metamask"
                        />
                    </div>
                    {/*<div>*/}
                    {/*    <Connection*/}
                    {/*        onConnect={props.handleConnectionDialogClose}*/}
                    {/*        connector={config.connectors.Ledger}*/}
                    {/*        icon={ledgerIcon}*/}
                    {/*        text="Ledger"*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div>
                        <Connection
                            onConnect={props.handleConnectionDialogClose}
                            connector={config.connectors.WalletConnect}
                            icon={walletConnect}
                            text="WalletConnect"
                        />
                    </div>
                    <div className={`${styles.divider}`}>
                        <Divider/>
                    </div>
                    <div className={`${styles.disconnectContainer}`}>
                        <Button
                            style={{textTransform: 'unset'}}
                            className={`${styles.disconnectButton}`}
                            onClick={() => {
                                deactivate();
                                localStorage.removeItem('walletconnect');
                                localStorage.removeItem('currentProvider');
                                props.handleConnectionDialogClose();
                            }}
                        >
                            <div>Disconnect</div>
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default ConnectionDialog;

import config from '../config/config';
import {Contract} from '@ethersproject/contracts';
import {Web3Provider} from '@ethersproject/providers';
import {parseEther} from '@ethersproject/units';
import {StakingOb} from "../components/swash-staking/swash-staking";

export function SwashService(provider: Web3Provider) {


    async function getSwashTokenContract(){

        let stakeIdLocal: string | null = localStorage.getItem('stakeId')
        let stakeId = (stakeIdLocal != null ? parseInt(stakeIdLocal): 0)

        let filtered: StakingOb[];
        let currentNetwork = await provider.getNetwork();

        filtered = config.staking.filter(value => value.networkId === currentNetwork.chainId);

        return new Contract(
            filtered[stakeId].tokenAddress,
            config.erc20ABI,
            provider?.getSigner(),
        )
    }
    return {
        async getBalance(accountAddress: string | null) {
            let contract = await getSwashTokenContract();
            let balance = await contract.balanceOf(accountAddress);
            return balance;
        },

        async allowance(
            accountAddress: string | null,
            swashStakingAddress: string,
        ) {
            return (await getSwashTokenContract()).allowance(accountAddress, swashStakingAddress);
        },
        async approve(swashStakingAddress: string) {
            return (await (await getSwashTokenContract()).approve(
                swashStakingAddress,
                parseEther('999999999999'),
            ));
        },
    };
}

import styles from './confirm-dialog.module.css';
import {Button, Dialog, Divider} from '@mui/material';
import React from 'react';

function ConfirmDialog(props: {
    open: boolean;
    handleDialogClose: any;
    handleConfirm: any;
    text: string;
}) {
    return (
        <div className={`${styles.container}`}>
            <Dialog open={props.open} onClose={props.handleDialogClose}>
                <div>
                    <div className={`${styles.headerText}`}>Confirmation</div>
                    <div className={`${styles.divider}`}>
                        <Divider/>
                    </div>
                    <div className={`${styles.bodyText}`}>{props.text}</div>
                    <div className={`${styles.divider}`}>
                        <Divider/>
                    </div>
                    <div className={`${styles.actionPanel}`}>
                        <div className={`${styles.noButton}`}>
                            <Button
                                className={`${styles.noButtonText}`}
                                style={{textTransform: 'unset'}}
                                onClick={props.handleDialogClose}
                            >
                                No
                            </Button>
                        </div>
                        <div className={`${styles.yesButton}`}>
                            <Button
                                className={`${styles.yesButtonText}`}
                                style={{textTransform: 'unset'}}
                                onClick={props.handleConfirm}
                            >
                                Yes
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

export default ConfirmDialog;

import styles from './staking-dialog.module.css';
import {Button, Dialog, Divider, IconButton} from '@mui/material';
import closeImage from '../../images/close.svg';
import React, {useContext} from 'react';
import ConfirmDialog from '../confirm-dialog/confirm-dialog';
import {SwashService} from '../../services/SwashService';
import {SwashStakingService} from '../../services/SwashStakingService';
import {useWeb3React} from '@web3-react/core';
import {formatEther, parseEther} from '@ethersproject/units';
import {LoadingContext, LoadingItem} from '../../context/loader';
import bigInt, {BigInteger} from "big-integer";
import {MessageContext} from "../../context/messaging";
import config from "../../config/config";

function StakingDialog(props: {
    open: boolean;
    handleStakingDialogClose: any;
    handleStakingDialogOpen: any;
    data: any;
}) {
    const [
        stakingConfirmDialogOpen,
        setStakingConfirmDialogOpen,
    ] = React.useState(false);
    const [stakeAmount, setStakeAmount] = React.useState('');
    const {library,chainId} = useWeb3React();
    const {
        loadingData,
        setLoadingData,
        setRefresh,
    } = useContext(LoadingContext);

    const {
        setMessageOpen,
        setSeverity,
        setMessageText
    } = useContext(MessageContext);

    const confirmText =
        'By approving you give the staking contract the ability to transfer your tokens. This is due to' +
        ' the ERC20 token standard’s requirement to approve tokens before transferring them to stake.';

    const handleStakingConfirmDialogOpen = () => {
        setStakingConfirmDialogOpen(true);
    };

    const handleStakingConfirmDialogClose = () => {
        setStakingConfirmDialogOpen(false);
    };

    function approveEnable() {
        return (
            props.data !== undefined &&
            !props.data.stakeApprove &&
            props.data.balance > 0
        );
    }

    function handleAmountChange(evt: React.ChangeEvent<HTMLInputElement>) {
        const financialGoal = (evt.target.validity.valid) ? evt.target.value : stakeAmount;
        setStakeAmount(financialGoal);
    }

    function stakeEnable() {
        let amount: BigInteger = bigInt(0);
        try {
            amount = bigInt(parseEther(stakeAmount).toString());
        } catch (e) {
            return false;
        } finally {
        }
        return (
            amount.compare(bigInt(0)) === 1 &&
            props.data !== undefined &&
            props.data.balance > 0 &&
            props.data.stakeApprove &&
            stakeAmount.charAt(stakeAmount.length - 1) !== '.' &&
            bigInt(parseEther(stakeAmount).toString()).compare(
                bigInt(props.data.balance.toString())) <= 0
        );
    }

    const handleStake = async () => {
        if (props.data.name === 'SwashStaking') {
            await stakeSwash();
        }
        setStakeAmount('');
    };

    async function stakeSwash() {
        loadingData.set('StakingDialog-stakeSwash', {message: 'Staking...'});
        setLoadingData(new Map<string, LoadingItem>(loadingData));
        let hasError = false;
        let tx = await SwashStakingService(library).stake(stakeAmount.toString()).catch((error) => {
            console.log(error);
            setMessageOpen(true);
            setSeverity("error");
            setMessageText('Staking failed.');
            hasError = true;
        });
        if (tx !== undefined) {
            await tx.wait().catch((error: any) => {
                console.log(error);
                setMessageOpen(true);
                setSeverity("error");
                setMessageText('Staking failed.');
                hasError = true;
            });
        }
        loadingData.delete('StakingDialog-stakeSwash');
        setLoadingData(new Map<string, LoadingItem>(loadingData));
        if (!hasError) {
            setRefresh('SwashStaking_' + new Date().getTime());
            setMessageOpen(true);
            setSeverity("success");
            setMessageText('Staking successfully done.');
        }
        props.handleStakingDialogClose();
    }

    const handleConfirm = async () => {
        handleStakingConfirmDialogClose();
        loadingData.set('Staking-approve', {message: 'Waiting for approval...'});
        setLoadingData(new Map<string, LoadingItem>(loadingData));
        let contractAddress = '';
        let stakeIdLocal: string | null = localStorage.getItem('stakeId')
        let stakeId = (stakeIdLocal != null ? parseInt(stakeIdLocal): 0)
        const stakeObj = config.staking.find((obj) => obj.stakeId === stakeId && obj.networkId === chainId)
        if (!stakeObj)
            throw 'Stake Id is invalid on this network'
        contractAddress = stakeObj.REACT_APP_STAKING_ADDRESS

        let hasError = false;

        let tx = await SwashService(library)
            .approve(contractAddress)
            .catch((error) => {
                console.log(error);
                setMessageOpen(true);
                setSeverity("error");
                setMessageText('Approving failed.');
                hasError = true;
            });
        if (tx !== undefined) {
            await tx.wait().catch((error: any) => {
                console.log(error);
                hasError = true;
                setMessageOpen(true);
                setSeverity("error");
                setMessageText('Approving failed.');
            });
        }
        loadingData.delete('Staking-approve');
        setLoadingData(new Map<string, LoadingItem>(loadingData));

        if (!hasError) {
            setRefresh('SwashStaking_' + new Date().getTime());
            setSeverity("success");
            setMessageOpen(true);
            setMessageText('Approving successfully done.');
        }
    };

    return (
        <div className={`${styles.container}`}>
            <ConfirmDialog
                open={stakingConfirmDialogOpen}
                handleConfirm={handleConfirm}
                text={confirmText}
                handleDialogClose={handleStakingConfirmDialogClose}
            />
            <Dialog open={props.open} onClose={props.handleStakingDialogClose}>
                <div>
                    <div className={`${styles.header}`}>
                        <div className={`${styles.headerText}`}>Swash Staking</div>
                        <div className={`${styles.closeImage}`}>
                            <IconButton
                                aria-label="Close"
                                onClick={() => {
                                    setStakeAmount('');
                                    props.handleStakingDialogClose();
                                }}
                            >
                                <img alt="" src={closeImage}/>
                            </IconButton>
                        </div>
                    </div>
                    <div className={`${styles.divider}`}>
                        <Divider/>
                    </div>
                    <div className={`${styles.balanceText}`}>
                        Your Balance: {props.data ? formatEther(props.data.balance) : 0}
                    </div>
                    <div className={`${styles.amountContainer}`}>
                        <div>
                            <div className={`${styles.amountLabel}`}>Amount</div>
                            <div className={`${styles.textContainer}`}>
                                <input
                                    pattern="[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)"
                                    id="txtAmount"
                                    type="text"
                                    value={stakeAmount}
                                    className={`${styles.text}`}
                                    onChange={handleAmountChange}
                                />
                            </div>
                        </div>
                        <div className={`${styles.maxButtonPanel}`}>
                            <Button
                                className={`${styles.maxButton}`}
                                style={{textTransform: 'unset'}}
                                onClick={() => {
                                    if (props.data !== null && props.data.balance != null) {
                                        setStakeAmount(formatEther(props.data.balance));
                                    } else {
                                        setStakeAmount('');
                                    }
                                }}
                            >
                                Max
                            </Button>
                        </div>
                    </div>
                    <div className={`${styles.divider}`}>
                        <Divider/>
                    </div>
                    <div className={`${styles.actionPanel}`}>
                        <div
                            className={
                                approveEnable()
                                    ? `${styles.approveButtonEnable}`
                                    : `${styles.approveButtonDisable}`
                            }
                        >
                            <Button
                                className={`${styles.approveButtonText}`}
                                style={{textTransform: 'unset'}}
                                onClick={handleStakingConfirmDialogOpen}
                                disabled={!approveEnable()}
                            >
                                Approve
                            </Button>
                        </div>
                        <div
                            className={
                                stakeEnable()
                                    ? `${styles.stakeButtonEnable}`
                                    : `${styles.stakeButtonDisable}`
                            }

                        >
                            <Button onClick={handleStake}
                                    className={`${styles.stakeButtonText}`}
                                    style={{textTransform: 'unset'}}
                                    disabled={!stakeEnable()}
                            >
                                Stake
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

export default StakingDialog;

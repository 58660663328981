import styles from './connection.module.css';
import {Button} from '@mui/material';
import {useWeb3React} from '@web3-react/core';

function Connection(props: {
    onConnect: any;
    connector: any;
    icon: string | undefined;
    text: string | null | undefined;
}) {
    const {activate} = useWeb3React();

    return (
        <div className={`${styles.container}`}>
            <Button
                style={{textTransform: 'unset'}}
                className={`${styles.connectButton}`}
                onClick={() => {
                    activate(props.connector, undefined, true);
                    localStorage.setItem('currentProvider', '' + props.text);
                    props.onConnect();
                }}
            >
                <div className={`${styles.connectButtonIcon}`}>
                    <img className={`${styles.connectIcon}`} alt="" src={props.icon}/>
                </div>
                <div className={`${styles.connectButtonText}`}>{props.text}</div>
            </Button>
        </div>
    );
}

export default Connection;

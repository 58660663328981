import {GeneralService} from './GeneralService';
import config from '../config/config';
import moment from 'moment';
import {Contract} from '@ethersproject/contracts';
import {Web3Provider} from '@ethersproject/providers';
import {SwashService} from './SwashService';
import {formatEther, parseUnits} from '@ethersproject/units';
import {Network} from "@ethersproject/networks";
import {StakingOb} from "../components/swash-staking/swash-staking";


export function SwashStakingService(provider: Web3Provider) {

    let filtered: StakingOb[];
    provider.getNetwork().then((currentNetwork:Network) =>{

        filtered = config.staking.filter(value => value.networkId === currentNetwork.chainId);

    });

    async function getSwashStakingContract(){
        console.log('Create contract with stakeId:');

        const stakingAddress = await getSwashStakingAddress();
        console.log(stakingAddress);
        return new Contract(
            stakingAddress,
            config.tangoStakingABI,
            provider?.getSigner(),
        )
    }
    async function getSwashStakingAddress(){
        let stakeIdLocal: string | null = localStorage.getItem('stakeId')
        let stakeId = (stakeIdLocal != null ? parseInt(stakeIdLocal): 0)

        let filtered: StakingOb[];
        let currentNetwork = await provider.getNetwork()

        filtered = config.staking.filter(value => value.networkId === currentNetwork.chainId);


        return filtered[stakeId].REACT_APP_STAKING_ADDRESS;
    }
    return {
        getSwashStakingContract,
        async getStakedBalance(

            accountAddress: string | null
        ) {
            const swashStakingContract = await getSwashStakingContract();
            let balance = await swashStakingContract.stakeOf(accountAddress);
            const data = {
                stakedBalance: Number(formatEther(balance)),
                stakedBalancePretty: GeneralService().prettyTotalStaked(
                    formatEther(balance.toString()),
                ),
            };
            return data;
        },
        async withdraw() {
            const swashStakingContract = await getSwashStakingContract();
            return await swashStakingContract.withdraw();
        },
        async getTimeStaked( accountAddress: string | null) {
            const swashStakingContract = await getSwashStakingContract();
            let timeObj = await swashStakingContract.timeStaked(accountAddress);
            let time = Number(timeObj.toString());
            if (time === 0) {
                let data = {
                    time: time.toString(),
                    claimEnabled: false,
                    withdrawDate: null,
                    withdrawDateMobile: null,
                    progress: '0',
                    timeStaked: 0
                };
                return data;
            }
            let canWithdrawObj = await swashStakingContract.canWithdraw(
                accountAddress,
            );
            let canWithdraw = Boolean(canWithdrawObj.toString());
            let depositDate = moment.unix(time);
            const now = Math.floor(new Date().getTime() / 1000);
            let depositTime = await swashStakingContract.depositTime();
            const stakingDuration = depositTime / 3600;

            let withdrawalDate = depositDate.add(
                stakingDuration,
                'hours',
            ); //add 3 month
            const total = Number(withdrawalDate.unix()) - Number(time);
            const progress = now - Number(time);
            const tempPercentage = (progress / total) * 100; // nest time progress
            const percentage = tempPercentage > 100 ? 100 : tempPercentage;
            const balanceObj = await swashStakingContract.stakeOf(accountAddress);
            const staked = Number(parseUnits(balanceObj.toString(), 'ether'));
            const nestRewardPercentage = (
                (await swashStakingContract.rewardPerc()) / 10
            ).toString();

            const totalReward = (staked * Number(nestRewardPercentage)) / 100; //calculate total reward after time period is over
            const progressReward = (totalReward * percentage) / 100; //calculate current progress reward
            let data = {
                time: time,
                claimEnabled: canWithdraw,
                withdrawDate: Number(withdrawalDate.unix()),
                withdrawDateMobile: withdrawalDate.format('DD, MMMM, YYYY HH:mm'),
                progress: Number(percentage).toFixed(2),
                progressReward: progressReward.toFixed(6),
                timeStaked: time
            };
            return data;
        },

        async checkApproval( accountAddress: string | null) {
            let balance = await SwashService(provider).getBalance(accountAddress);
            balance = parseUnits(balance.toString(), 'ether');

            const allowance = await SwashService(provider).allowance(
                accountAddress,
                await getSwashStakingAddress(),
            );
            console.log('######################################## check approval')
            console.log(allowance.toString())
            const ethAllowance = parseUnits(allowance.toString(), 'ether');
            return parseFloat(ethAllowance.toString()) >= parseFloat(balance.toString());
        },
        async stake( stakeAmount: string) {
            const stakeAmountNum = parseUnits(stakeAmount, 'ether');
            const swashStakingContract = await getSwashStakingContract();
            return await swashStakingContract.stake(stakeAmountNum);
        },
        async getContractInfo() {
            let data = {
                stakingDuration: 0,
                stakingCapacity: '',
                stakedTotal: '',
                stakingStarts: 0,
                stakingEnds: 0,
                rewardPerc: '',
                poolStatus: ''
            };
            const swashStakingContract = await getSwashStakingContract();

            console.log('getContractInfo')
            console.log('getContractInfo')
            let stakeIdLocal: string | null = localStorage.getItem('stakeId')
            let stakeId = (stakeIdLocal != null ? parseInt(stakeIdLocal): 0)

            console.log(stakeId)
            let depositTime = await swashStakingContract.depositTime();
            let stakingCapacity = await swashStakingContract.stakingCap();
            data.stakedTotal = (await swashStakingContract.stakedTotal()).toString();
            data.stakingStarts = Number((await swashStakingContract.stakingStarts()).toString());
            data.stakingEnds = Number((await swashStakingContract.stakingEnds()).toString());
            data.rewardPerc = ((await swashStakingContract.rewardPerc()) / 10).toString();

            data.stakingDuration = depositTime / (24 * 3600);
            data.stakingCapacity = (
                Number(formatEther(stakingCapacity))
            ).toString();
            const now = moment().unix();

            if (Number(formatEther(data.stakedTotal)) < Number(data.stakingCapacity) && now >= data.stakingStarts && now <= data.stakingEnds) {
                data.poolStatus = 'OPEN';
            } else {
                data.poolStatus = 'CLOSED';
            }

            return data;
        },
    };
}

import {Web3Provider} from '@ethersproject/providers';
import React, {useContext, useEffect} from 'react';
import {Button, FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import config from '../../config/config';
import swapImage from '../../images/swash.svg';

import styles from './swash-staking.module.css';
import {LoadingContext} from '../../context/loader';
import {useWeb3React} from '@web3-react/core';
import {Network} from "@ethersproject/networks";
import SwashStaking from "./swash-staking";
import LiquidityMining from "../liquidity-mining/liquidity-mining.";
import StakingDialog from "../staking-dialog/staking-dialog";
import connectImage from "../../images/connect.svg";
import ConnectionDialog from "../connection-dialog/connection-dialog";
import LabelText from "../label-text/label-text";

class StakingOb {

    constructor(public stakeId: number,
                public networkId: number,
                public tokenAddress: string,
                public staking_title: string,
                public REACT_APP_STAKING_FINISH_DATE: string,
                public REACT_APP_STAKING_ADDRESS: string) {
    }
}

const supportedNetworks = process.env.REACT_APP_NETWORK_ID ? process.env.REACT_APP_NETWORK_ID.split(',') : ['1']

function StakingOrLiquidityWrapper(props: any) {
    const {library, account, chainId} = useWeb3React();
    const {refresh} = useContext(
        LoadingContext,
    );

    let [stakeId, setStakeId] = React.useState(0);
    let [filteredStaking, setFilteredStaking] = React.useState<StakingOb[]>([]);


    const [stakingDialogOpen, setStakingDialogOpen] = React.useState(false);
    const [dialogData, setDialogData] = React.useState();

    const handleStakingDialogOpen = (data: any) => {
        setDialogData(data);
        setStakingDialogOpen(true);
    };

    const handleStakingDialogClose = () => {
        setStakingDialogOpen(false);
    };

    const handleSetDialogData = (data: any) => {
        setDialogData(data);
    };

    function stakingOrLiguidityUI() {
        let localStakeId: string | null = localStorage.getItem('stakeId')
        let selectedComboItem = (localStakeId != null ? parseInt(localStakeId) : 0);

        if (config.staking[selectedComboItem].type === 'liquidity') {
            return <LiquidityMining handleStakingDialogOpen={handleStakingDialogOpen}/>;
        } else {
            return <SwashStaking handleSetDialogData={handleSetDialogData}
                                 handleStakingDialogOpen={handleStakingDialogOpen}/>;
        }
    }

    async function fetchData(
        account: string | null | undefined,
        provider: Web3Provider,
    ) {
        if (refresh !== '' && !refresh.includes('SwashStaking_')) {
            return;
        }
        // loadingData.set('SwashStakingLoadData', {message: 'Loading staking info'});
        // setLoadingData(new Map<string, LoadingItem>(loadingData));


        if (account === undefined) {
            console.log('no account');
            // loadingData.delete('SwashStakingLoadData');
            // setLoadingData(new Map<string, LoadingItem>(loadingData));
            return;
        }

        // loadingData.delete('SwashStakingLoadData');
        // setLoadingData(new Map<string, LoadingItem>(loadingData));
    }

    useEffect(() => {
        if (!library) {
            setFilteredStaking([]);

            return;
        }

        let localStakeId: string | null = localStorage.getItem('stakeId')
        console.log('localStakeId')
        console.log(localStakeId)
        setStakeId((localStakeId != null ? parseInt(localStakeId) : 0));

        fetchData(account, library);

        setFilteredStaking([]);
        library.getNetwork().then((currentNetwork: Network) => {

            let filtered: StakingOb[] = config.staking.filter(value => value.networkId === currentNetwork.chainId);
            setFilteredStaking(filtered);

        });
        console.log('---------------------chainId -----------------------')
        console.log('---------------------chainId -----------------------')
        console.log('---------------------chainId -----------------------')
        console.log('---------------------chainId -----------------------')
        console.log(chainId)
        console.log(account)
        console.log(refresh)
        if(chainId)console.log(supportedNetworks.includes(chainId.toString()))
    }, [account, library, refresh]);

    const [connectionDialogOpen, setConnectionDialogOpen] = React.useState(false);
    const {setRefresh} = useContext(LoadingContext);

    const handleConnectionDialogOpen = () => {
        setConnectionDialogOpen(true);
    };

    const handleConnectionDialogClose = () => {
        setConnectionDialogOpen(false);
    };
    // @ts-ignore
    const blurStyle: CSS.Properties = (chainId && supportedNetworks.includes(chainId.toString())) ? {} : {filter: 'blur(5px)'};
    return (
        <div>

            <ConnectionDialog
                open={connectionDialogOpen}
                handleConnectionDialogClose={handleConnectionDialogClose}
            />
            <div>

                <div hidden={!(chainId && !supportedNetworks.includes(`${chainId}`))}
                     className={`${styles.notConnectedWalletDivStyle}`} style={{paddingTop: '150px'}}>
                    Staking is only available on <b>Ethereum</b>, <b>Polygon</b>, <b>Bsc</b>, and <b>Gnosis Chain/xDai</b>. <br/>
                    Please select the right network
                </div>
                <div hidden={!!chainId}
                     className={`${styles.notConnectedWalletDivStyle}`}>
                    <b>Swash staking is now live!</b><br/>
                    <div style={{textAlign:'left', fontSize: '13px'}}>
                    Connect your wallet to start staking and earn rewards on your $SWASH!<br/>
                    All pools info will be available once you connect your wallet and set your network.<br/>
                    Staking is available on all 3 networks: <b>Ethereum</b>, <b>Polygon</b>, and <b>Gnosis Chain/xDai</b><br/><br/>
                    </div>
                    <div className={`${styles.popupLabelTextContainer}`}>
                        <LabelText label="APY" text='100%'/>
                    </div>

                    <div className={`${styles.popupLabelTextContainer}`}>
                        <LabelText label="Stake token" text='$SWASH'/>
                    </div>

                    <div className={`${styles.popupLabelTextContainer}`}>
                        <LabelText label="Rewards in" text='$SWASH'/>
                    </div>

                    <div className={`${styles.popupLabelTextContainer}`}>
                        <LabelText label="Lockup" text='3 Months'/>
                    </div>

                    <div className={`${styles.popupLabelTextContainer}`}>
                        <LabelText label="Cap on Ethereum" text='4M'/>
                    </div>

                    <div className={`${styles.popupLabelTextContainer}`}>
                        <LabelText label="Cap on Polygon" text='6M'/>
                    </div>

                    <div className={`${styles.popupLabelTextContainer}`}>
                        <LabelText label="Cap on Gnosis Chain/xDai" text='2M'/>
                    </div>
                    <div className={`${styles.buttonContainer}`}>
                        <div className={`${styles.connectButton} ${styles.center}`} onClick={handleConnectionDialogOpen}>
                            <Button
                                title={account === undefined || account === null ? '' : account}
                                className={`${styles.button}`}
                            >
                                <div className={`${styles.connectButtonIcon}`}>
                                    <img alt="" src={connectImage}/>
                                </div>
                                <div className={`${styles.connectButtonText}`}>
                                    {account === undefined || account === null
                                        ? 'Connect Wallet'
                                        : account
                                            ? `${account.substring(0, 6)}...${account.substring(
                                                account.length - 4,
                                            )}`
                                            : ''}
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>

                <div style={blurStyle} className={`${styles.container}`}>
                    <div className={`${styles.header}`}>

                        <FormControl  className={`${styles.selectStaking}`}>
                            <InputLabel id="demo-simple-select-helper-label">Staking</InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                value={stakeId}
                                label="Staking">

                                {filteredStaking.map(function (menuItem: StakingOb, i) {

                                    return (
                                        <MenuItem onClick={() => {
                                            setStakeId(menuItem.stakeId)
                                            window.location.reload();
                                            console.log('set local');
                                            console.log(menuItem.stakeId);
                                            localStorage.setItem('stakeId', menuItem.stakeId.toString());
                                        }} key={i} value={menuItem.stakeId}>{menuItem.staking_title}</MenuItem>
                                    )

                                })}

                            </Select>

                        </FormControl>
                        <div className={`${styles.stakingWithImage}`}>
                            <div className={`${styles.swashIcon}`}>
                                <img alt="" src={swapImage}/>
                            </div>
                            <div className={`${styles.stakingText}`}>Staking</div>
                        </div>
                    </div>
                    <div>
                        <StakingDialog
                            open={stakingDialogOpen}
                            data={dialogData}
                            handleStakingDialogOpen={handleStakingDialogOpen}
                            handleStakingDialogClose={handleStakingDialogClose}
                        />
                        {stakingOrLiguidityUI()}
                    </div>
                </div>
            </div>
        </div>
    );

}

export default StakingOrLiquidityWrapper;
import config from '../config/config';
import moment from 'moment';
import {Contract} from '@ethersproject/contracts';
import {Web3Provider} from '@ethersproject/providers';
import {formatEther, parseUnits} from '@ethersproject/units';

export function VestingSeedService(provider: Web3Provider) {
    const vestingSeedAddress = process.env.REACT_APP_VESTING_SEED_ADDRESS || '';
    const vestingSeedContract = new Contract(
        vestingSeedAddress,
        config.vestingABI,
        provider?.getSigner(),
    );

    return {
        async getClaimData(accountAddress: string | null) {
            const startDateUnix = await vestingSeedContract.startDate();
            const userRecipient = await vestingSeedContract.recipients(accountAddress);

            const availableClaim = await vestingSeedContract.hasClaim();
            const withdrawnAmount = userRecipient.withdrawnAmount;
            const vestedSoFar = availableClaim.add(withdrawnAmount);
            const recipientTotalShare = userRecipient.recipientTotalShare;
            const progress = (vestedSoFar / recipientTotalShare) * 100;
            let startDate = moment.unix(startDateUnix);
            const startDateDisplay = moment.unix(startDateUnix);
            const duration = await vestingSeedContract.vestingDays();
            let withdrawalDate = startDate.add(
                Number(duration.toString()),
                'days',
            );
            return {
                recipientTotalShare: formatEther(recipientTotalShare),
                progress: progress.toString() === 'NaN' ? 0 : progress,
                availableClaim: formatEther(availableClaim),
                startDate: startDateDisplay.format('DD, MMMM, YYYY'),
                endDate: withdrawalDate.format('DD, MMMM, YYYY'),
                vestedSoFar: formatEther(vestedSoFar),
            };
        },
        async getClaimAmounts(amount: number) {
            const claimAmount = await vestingSeedContract.hasClaim(amount);
            const data = {
                claimAmount: parseUnits(claimAmount, 'ether'),
            };
            return data;
        },
        async claim() {
            return await vestingSeedContract.claim();
        },
    };
}

import styles from "./tutorials.module.css";
import React from "react";

function Tutorials() {
    return(<div>
        <div className="contents">
            <div className={`${styles.container}`}>
            <div className={`${styles.header}`}>
                <div className={`${styles.title}`}>Swash Staking</div>
            </div>
                <video width={'100%'} height={'auto'} controls>
                    <source src={'https://claim.swashapp.io/videos/swash-staking.mp4'} type={'video/mp4'}/>
                </video>
        </div>
        </div>
        <hr/>
        <div className="contents">
            <div className={`${styles.container}`}>
                <div className={`${styles.header}`}>
                    <div className={`${styles.title}`}>Uniswap Liquidity Providing</div>
                </div>
                <video width={'100%'} height={'auto'} controls>
                    <source src={'https://claim.swashapp.io/videos/uniswap-liquidity-providing.mp4'} type={'video/mp4'}/>
                </video>
            </div>
        </div>
        <hr/>
        <div className="contents">
            <div className={`${styles.container}`}>
                <div className={`${styles.header}`}>
                    <div className={`${styles.title}`}>LP Staking</div>
                </div>
                <video width={'100%'} height={'auto'} controls>
                    <source src={'https://claim.swashapp.io/videos/lp-staking.mp4'} type={'video/mp4'}/>
                </video>
            </div>
            <div className={`${styles.container}`}>
                <div className={`${styles.header}`}>
                    <div className={`${styles.title}`}>LP Unstaking</div>
                </div>
                <video width={'100%'} height={'auto'} controls>
                    <source src={'https://claim.swashapp.io/videos/lp-unstaking.mp4'} type={'video/mp4'}/>
                </video>
            </div>
            <div className={`${styles.container}`}>
                <div className={`${styles.header}`}>
                    <div className={`${styles.title}`}>Claim LP Rewards</div>
                </div>
                <video width={'100%'} height={'auto'} controls>
                    <source src={'https://claim.swashapp.io/videos/claim-lp-rewards.mp4'} type={'video/mp4'}/>
                </video>
            </div>
            <div className={`${styles.container}`}>
                <div className={`${styles.header}`}>
                    <div className={`${styles.title}`}>Withdraw LP Token</div>
                </div>
                <video width={'100%'} height={'auto'} controls>
                    <source src={'https://claim.swashapp.io/videos/widthraw-lp-token.mp4'} type={'video/mp4'}/>
                </video>
            </div>
        </div>
    </div>);
}

export default Tutorials;
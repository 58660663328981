import styles from './liquidity-stake-dialog.module.css';
import {Button, Dialog, Divider, IconButton} from '@mui/material';
import closeImage from '../../images/close.svg';
import React, {useContext} from 'react';
import {useWeb3React} from '@web3-react/core';
import {LoadingContext, LoadingItem} from '../../context/loader';
import {MessageContext} from "../../context/messaging";
import MaterialTable from "material-table";

import {UniSwapV3Service} from "../../services/UniSwapV3Staker";
import LightTooltip from "../tooltip/swash-tooltip";

function LiquidityStakeDialog(props: {
    open: boolean;
    handleStakeDialogClose: any;
    data: any;
}) {

    const {library, account} = useWeb3React();
    const {
        setRefresh, setLoadingData, loadingData
    } = useContext(LoadingContext);

    const {
        setMessageOpen,
        setSeverity,
        setMessageText
    } = useContext(MessageContext);

    const tableRef = React.createRef();

    const doStake = async (selectedRow: any) => {
        loadingData.set('LiquidityMining-stakeLiquidity', {message: 'Staking...'});
        setLoadingData(new Map<string, LoadingItem>(loadingData));
        let hasError = false;
        let tx = await UniSwapV3Service(library).safeTransferFrom(account, Number(selectedRow.tokenId)).catch((error) => {
            console.log(error);
            setMessageOpen(true);
            setSeverity("error");
            setMessageText('Staking failed.');
            hasError = true;
        });
        if (tx !== undefined) {
            await tx.wait().catch((error: any) => {
                console.log(error);
                hasError = true;
                setMessageOpen(true);
                setSeverity("error");
                setMessageText('Staking failed.');
            });
        }
        loadingData.delete('LiquidityMining-stakeLiquidity');
        setLoadingData(new Map<string, LoadingItem>(loadingData));
        if (!hasError) {
            setRefresh('LiquidityMining_' + new Date().getTime());
            setMessageOpen(true);
            setSeverity("success");
            setMessageText('Staking successfully done.');
        }
    }

    return (
        <div className={`${styles.container}`}>
            <Dialog PaperProps={{
                sx: {
                    minWidth: 700,
                }
            }}
                    open={props.open} onClose={props.handleStakeDialogClose}>
                <div>
                    <div className={`${styles.header}`}>
                        <div className={`${styles.headerText}`}>Your Staking Positions</div>
                        <div className={`${styles.closeImage}`}>
                            <IconButton
                                aria-label="Close"
                                onClick={() => {
                                    props.handleStakeDialogClose();
                                }}
                            >
                                <img alt="" src={closeImage}/>
                            </IconButton>
                        </div>
                    </div>
                    <div className={`${styles.divider}`}>
                        <Divider/>
                    </div>
                    <div className={`${styles.tableContainer}`}>
                        {/* @ts-ignore */}
                        <MaterialTable
                            tableRef={tableRef}
                            options={{
                                search: false,
                                selection: false,
                                toolbar: false,
                                paging: false,
                                sorting: false,
                                grouping: false,
                                draggable: false,
                                actionsColumnIndex: -1,

                                maxBodyHeight: 300,
                                headerStyle: {
                                    backgroundColor: '#E0F4FA',
                                    color: 'black',
                                    textAlign: 'center',
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    margin: 0
                                }
                            }}
                            title="Simple Action Preview"
                            columns={[
                                {title: 'Token Id', field: 'tokenId', cellStyle: {textAlign: 'center'}},
                                {
                                    title: 'Min', cellStyle: {textAlign: 'center'},
                                    render: (rowData: { title: string, min: string, max: string }) =>
                                        <LightTooltip
                                            title={rowData.title} placement="top-start">
                                            <div>{rowData.min}</div>
                                        </LightTooltip>
                                },
                                {
                                    title: 'Max', cellStyle: {textAlign: 'center'},
                                    render: (rowData: { title: string, min: string, max: string }) =>
                                        <LightTooltip
                                            title={rowData.title} placement="top-start">
                                            <div>{rowData.max}</div>
                                        </LightTooltip>
                                },                                {
                                    cellStyle: {paddingLeft: 0, paddingRight: 0},
                                    title: '',
                                    render: rowData => <div className={`${styles.stakeButtonEnable}`}>
                                        <Button className={`${styles.stakeButtonText}`}
                                                onClick={() => {
                                                    doStake(rowData);
                                                }
                                                }
                                                style={{textTransform: 'unset'}}>Stake</Button></div>
                                }
                            ]}
                            data={props.data}
                        />
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

export default LiquidityStakeDialog;

import styles from './presale-token-claim.module.css';
import VerticalLabelText from '../vertical-label-text/vertical-label-text';
import {Button, Divider, Grid} from '@mui/material';
import SwashProgress from '../bordered-linear-progress/swash-progress';
import LabelText from '../label-text/label-text';
import ConfirmDialog from '../confirm-dialog/confirm-dialog';
import React, {useContext, useEffect} from 'react';
import {useWeb3React} from "@web3-react/core";
import {LoadingContext, LoadingItem} from "../../context/loader";
import {Web3Provider} from "@ethersproject/providers";
import {VestingPreSaleService} from "../../services/VestingPreSaleService";
import {MessageContext} from "../../context/messaging";

function PresaleTokenClaim(props: any) {
    const [claimConfirmDialogOpen, setClaimConfirmDialogOpen] = React.useState(
        false,
    );

    const handleClaimConfirmDialogOpen = () => {
        setClaimConfirmDialogOpen(true);
    };

    const handleClaimConfirmDialogClose = () => {
        setClaimConfirmDialogOpen(false);
    };
    const handleConfirm = () => {
        claimTokens(account, library);
        setClaimConfirmDialogOpen(false);
    };
    const {library, account} = useWeb3React();
    const {setLoadingData, loadingData, refresh, setRefresh} = useContext(LoadingContext);
    const [dataModel, setDataModel] = React.useState({
        recipientTotalShare: '',
        name: 'Seed Token Claim',
        startDate: '',
        availableClaim: '',
        endDate: '',
        progress: '0',
        vestedSoFar: ''
    });

    const {
        setMessageOpen,
        setSeverity,
        setMessageText
    } = useContext(MessageContext);

    useEffect(() => {
        fetchData(account, library);
    }, [account, setLoadingData, setDataModel, refresh, setRefresh]);
    return (
        <div className={`${styles.container}`}>
            <ConfirmDialog
                open={claimConfirmDialogOpen}
                handleConfirm={handleConfirm}
                text="Do you want to claim your tokens?"
                handleDialogClose={handleClaimConfirmDialogClose}
            />
            <div className={`${styles.title}`}>Swash Pre-sale Token Claim</div>
            <div className={`${styles.headerDivider}`}>
                <Divider/>
            </div>
            <div className={`${styles.labelTextContainer}`}>
                <Grid container rowSpacing={1}>
                    <Grid item xs={4}>
                        <VerticalLabelText label="Start date:" text={dataModel.startDate}/>
                    </Grid>
                    <Grid item xs={4}>
                        <VerticalLabelText label="Vested so far:"
                                           text={dataModel.vestedSoFar === '' ? '' : Number(dataModel.vestedSoFar) + ''}/>
                    </Grid>
                    <Grid item xs={4}>
                        <VerticalLabelText label="End date:" text={dataModel.endDate}/>
                    </Grid>
                </Grid>
            </div>
            <div className={`${styles.progress}`}>
                <SwashProgress borderRadius={50} height={32} value={Number(dataModel.progress)}/>
            </div>
            <div className={`${styles.progress}`}>
                <LabelText label="Total Share:" text={Number(dataModel.recipientTotalShare) + ''}
                           showDelimiter={false}/>
            </div>
            <div className={`${styles.progress}`}>
                <LabelText label="Available to claim:" text={Number(dataModel.availableClaim) + ''}
                           showDelimiter={false}/>
            </div>
            <div className={`${styles.footerDivider}`}>
                <Divider/>
            </div>
            <div className={isClaimEnable() ? `${styles.claimButtonEnable}` : `${styles.claimButtonDisable}`}>
                <Button
                    disabled={!isClaimEnable()}
                    className={`${styles.claimButtonText}`}
                    onClick={handleClaimConfirmDialogOpen}
                >
                    Claim
                </Button>
            </div>
        </div>
    );

    function isClaimEnable() {
        return Number(dataModel.availableClaim) > 0;
    }

    async function claimTokens(account: string | null | undefined,
                               provider: Web3Provider) {
        loadingData.set('presale-claimTokens', {message: 'Claiming...'});
        setLoadingData(new Map<string, LoadingItem>(loadingData));
        let hasError = false;

        let tx = await VestingPreSaleService(provider).claim().catch((error) => {
            console.log(error);
            setMessageOpen(true);
            setSeverity("error");
            setMessageText('Claiming failed.');
            hasError = true;
        });
        if (tx !== undefined) {
            await tx.wait().catch((error: any) => {
                console.log(error);
                setMessageOpen(true);
                setSeverity("error");
                setMessageText('Claiming failed.');
                hasError = true;
            });
        }
        loadingData.delete('presale-claimTokens');
        setLoadingData(new Map<string, LoadingItem>(loadingData));
        if (!hasError) {
            setRefresh("PreSaleTokenClaim_" + new Date().getTime());
            setMessageOpen(true);
            setSeverity("success");
            setMessageText('Claiming successfully done.');
        }
    }

    function fetchData(
        account: string | null | undefined,
        provider: Web3Provider,
    ) {
        if (refresh !== '' && !refresh.includes('PreSaleTokenClaim_')) {
            return;
        }
        loadingData.set('LoadingPreSaleClaimData', {message: 'Waiting for claim info'});
        setLoadingData(new Map<string, LoadingItem>(loadingData));
        let model = {
            recipientTotalShare: '',
            name: 'Seed Token Claim',
            startDate: '',
            availableClaim: '',
            endDate: '',
            progress: '0',
            vestedSoFar: ''
        };
        if (account === undefined) {
            loadingData.delete('LoadingPreSaleClaimData');
            setLoadingData(new Map<string, LoadingItem>(loadingData));
            console.log('no account');
            setDataModel(model);
            return;
        }

        VestingPreSaleService(provider)
            .getClaimData(account)
            .then(claimData => {
                model.recipientTotalShare = claimData.recipientTotalShare;
                model.startDate = claimData.startDate;
                model.endDate = claimData.endDate;
                model.availableClaim = Number(claimData.availableClaim).toFixed(4).toString();
                model.progress = claimData.progress.toString();
                model.vestedSoFar = Number(claimData.vestedSoFar).toFixed(4).toString();
                setDataModel(model);
                loadingData.delete('LoadingPreSaleClaimData');
                setLoadingData(new Map<string, LoadingItem>(loadingData));
            }).catch((error) => {
            console.log(error);
            loadingData.delete('LoadingPreSaleClaimData');
            setLoadingData(new Map<string, LoadingItem>(loadingData));
        });
    }
}

export default PresaleTokenClaim;

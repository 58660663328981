import styles from './loading-dialog.module.css';
import {Dialog} from '@mui/material';
import React, {useContext} from 'react';
import circleImage from '../../images/circle.svg';
import Loader from 'react-loader-spinner';
import {LoadingContext} from '../../context/loader';

function LoadingDialog() {
    const {loadingData} = useContext(LoadingContext);
    let allTexts: string[] = [];
    loadingData.forEach((item) => {
        if (!allTexts.includes(item.message)) {
            allTexts.push(item.message);
        }
    })
    return (
        <div className={`${styles.container}`}>
            <Dialog open={loadingData.size > 0} aria-modal={true}>
                <div className={`${styles.circleImageGrid}`}>
                    <div className={`${styles.content}`}>
                        <img alt="" src={circleImage}/>
                    </div>
                    <div className={`${styles.overlay}`}>
                        {/* @ts-ignore */}
                        <Loader type="ThreeDots" color="#00BFFF" height={11}/>
                    </div>
                </div>
                <div className={`${styles.textContainer}`}>
                    {
                        allTexts.map((text: any, index) =>
                            <div key={index} className={`${styles.text}`}> {text}</div>
                        )
                    }
                </div>
            </Dialog>
        </div>
    );
}

export default LoadingDialog;

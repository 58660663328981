import {createContext, ReactChild, ReactFragment, ReactPortal, useMemo, useState,} from 'react';

export type LoadingContent = {
    refresh: string;
    setRefresh: (c: string) => void;
    loadingData: Map<string, LoadingItem>;
    setLoadingData: (t: Map<string, LoadingItem>) => void;
};
export type LoadingItem = {
    message: string;
}
export const LoadingContext = createContext<LoadingContent>({
    refresh: '',
    setRefresh: (refresh: string) => {
    },
    loadingData: new Map<string, LoadingItem>(),
    setLoadingData: (data: Map<string, LoadingItem>) => {
    },
});

export const LoadingProvider = (props: {
    children:
        | boolean
        | ReactChild
        | ReactFragment
        | ReactPortal
        | null
        | undefined;
}) => {
    const [loadingData, setLoadingData] = useState(new Map<string, LoadingItem>());
    const [refresh, setRefresh] = useState('');

    const value = useMemo(
        () => {
            return ({
                refresh,
                setRefresh,
                loadingData,
                setLoadingData,
            });
        },
        [loadingData, refresh, setLoadingData]
    );

    return (
        <LoadingContext.Provider value={value}>
            {props.children}
        </LoadingContext.Provider>
    );
};

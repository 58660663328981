import * as React from 'react';
import {useContext} from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, {AlertProps} from '@mui/material/Alert';
import {MessageContext} from "../../context/messaging";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Message() {
    const {messageOpen, severity, messageText, setMessageOpen} = useContext(MessageContext);

    const handleClose = () => {
        setMessageOpen(false);
    };

    return (
        <Stack spacing={2} sx={{width: '100%'}}>
            <Snackbar open={messageOpen} autoHideDuration={6000} onClose={handleClose}
                      anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right'
                      }}>
                <Alert onClose={handleClose} severity={severity === "error" ? "error" : "success"}
                       sx={{width: '100%'}}>
                    {messageText}
                </Alert>
            </Snackbar>
        </Stack>
    );
}

import {InjectedConnector} from '@web3-react/injected-connector';
import {WalletConnectConnector} from '@web3-react/walletconnect-connector';
import {LedgerConnector} from '@web3-react/ledger-connector';

const POLLING_INTERVAL = 12000;

const RPC_URLS = {
    1: "https://mainnet.infura.io/v3/abc0618acca547bdb302950aaf487221",
    4: "https://rinkeby.infura.io/v3/abc0618acca547bdb302950aaf487221",
    100: "https://rpc.xdaichain.com",
    137: "https://polygon-rpc.com/",
    56: "https://bsc-dataseed.binance.org/",
};

export const injected = new InjectedConnector({
    supportedChainIds: undefined,

});

export const walletconnect = new WalletConnectConnector({
    supportedChainIds: [1, 4, 100, 137, 56],
    rpc: {1: RPC_URLS[1], 4: RPC_URLS[4], 100: RPC_URLS[100], 137: RPC_URLS[137], 56: RPC_URLS[56]},
    bridge: 'https://uniswap.bridge.walletconnect.org',
    qrcode: true,
    infuraId: '18ff218710b14f979f2724dd91ab00f9',
});

export const ledger = new LedgerConnector(
    {chainId: 1, url: RPC_URLS[1], pollingInterval: POLLING_INTERVAL});

const config = {
    connectors: {
        Injected: injected,
        WalletConnect: walletconnect,
        Ledger: ledger,
    },
    universalGasPrice: '70',
    liquidityMiningABI: [
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_tokenAddress',
                    type: 'address',
                },
                {
                    internalType: 'address',
                    name: '_tokenLiquidityMiningWallet',
                    type: 'address',
                },
                {
                    internalType: 'uint256',
                    name: '_startBlock',
                    type: 'uint256',
                },
            ],
            stateMutability: 'nonpayable',
            type: 'constructor',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'user',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'poolId',
                    type: 'uint256',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                },
            ],
            name: 'Deposit',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'user',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'poolId',
                    type: 'uint256',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                },
            ],
            name: 'EmergencyWithdraw',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'previousOwner',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address',
                },
            ],
            name: 'OwnershipTransferred',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'user',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'poolId',
                    type: 'uint256',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                },
            ],
            name: 'SendTokenReward',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                },
            ],
            name: 'TokenPerBlockSet',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'user',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'uint256',
                    name: 'poolId',
                    type: 'uint256',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                },
            ],
            name: 'Withdraw',
            type: 'event',
        },
        {
            inputs: [],
            name: 'START_BLOCK',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '_tokenPerBlock',
                    type: 'uint256',
                },
                {
                    internalType: 'contract IERC20',
                    name: '_token',
                    type: 'address',
                },
                {
                    internalType: 'bool',
                    name: '_withUpdate',
                    type: 'bool',
                },
            ],
            name: 'add',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_address',
                    type: 'address',
                },
            ],
            name: 'changeRewardsWallet',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '_poolId',
                    type: 'uint256',
                },
            ],
            name: 'claimReward',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '_poolId',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: '_amount',
                    type: 'uint256',
                },
            ],
            name: 'deposit',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '_poolId',
                    type: 'uint256',
                },
            ],
            name: 'emergencyWithdraw',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '_from',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: '_to',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: '_tokenPerBlock',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: '_waitForWithdraw',
                    type: 'uint256',
                },
            ],
            name: 'getPoolReward',
            outputs: [
                {
                    internalType: 'uint256',
                    name: 'rewards',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'massUpdatePools',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [],
            name: 'owner',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '_poolId',
                    type: 'uint256',
                },
                {
                    internalType: 'address',
                    name: '_user',
                    type: 'address',
                },
            ],
            name: 'pendingReward',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            name: 'poolInfo',
            outputs: [
                {
                    internalType: 'contract IERC20',
                    name: 'token',
                    type: 'address',
                },
                {
                    internalType: 'uint256',
                    name: 'tokenPerBlock',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: 'lastRewardBlock',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: 'acctokenPerShare',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: 'waitForWithdraw',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'poolLength',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'renounceOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '_poolId',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: '_tokenPerBlock',
                    type: 'uint256',
                },
                {
                    internalType: 'bool',
                    name: '_withUpdate',
                    type: 'bool',
                },
            ],
            name: 'set',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [],
            name: 'tokenLiquidityMiningWallet',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address',
                },
            ],
            name: 'tokenToPoolId',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'tokenToken',
            outputs: [
                {
                    internalType: 'contract IERC20',
                    name: '',
                    type: 'address',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address',
                },
            ],
            name: 'transferOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '_poolId',
                    type: 'uint256',
                },
            ],
            name: 'updatePool',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
                {
                    internalType: 'address',
                    name: '',
                    type: 'address',
                },
            ],
            name: 'userInfo',
            outputs: [
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: 'rewardDebt',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: 'rewardDebtAtBlock',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '_poolId',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: '_amount',
                    type: 'uint256',
                },
            ],
            name: 'withdraw',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
    ],
    tangoStakingABI: [
        {
            inputs: [
                {
                    internalType: 'string',
                    name: 'name_',
                    type: 'string',
                },
                {
                    internalType: 'address',
                    name: '_token',
                    type: 'address',
                },
                {
                    internalType: 'uint256',
                    name: '_stakingStarts',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: '_stakingEnds',
                    type: 'uint256',
                },
                {
                    internalType: 'address',
                    name: '_rewardAddress',
                    type: 'address',
                },
                {
                    internalType: 'uint256',
                    name: '_rewardPerc',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: '_depositTime',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: '_stakingCap',
                    type: 'uint256',
                },
            ],
            stateMutability: 'nonpayable',
            type: 'constructor',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'bool',
                    name: 'value',
                    type: 'bool',
                },
            ],
            name: 'DepositEnabledSet',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'value',
                    type: 'uint256',
                },
            ],
            name: 'DepositTimeSet',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'sender',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'token',
                    type: 'address',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount_',
                    type: 'uint256',
                },
            ],
            name: 'EmergencyWithdrawAdminDone',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'sender',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'token',
                    type: 'address',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount_',
                    type: 'uint256',
                },
            ],
            name: 'EmergencyWithdrawDone',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'previousOwner',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address',
                },
            ],
            name: 'OwnershipTransferred',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'token',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'staker_',
                    type: 'address',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount_',
                    type: 'uint256',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'reward_',
                    type: 'uint256',
                },
            ],
            name: 'PaidOut',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'sender',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'rewardAddress',
                    type: 'address',
                },
            ],
            name: 'RewardAddressChanged',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'value',
                    type: 'uint256',
                },
            ],
            name: 'RewardPercSet',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'token',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'staker_',
                    type: 'address',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'requestedAmount_',
                    type: 'uint256',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'stakedAmount_',
                    type: 'uint256',
                },
            ],
            name: 'Staked',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'value',
                    type: 'uint256',
                },
            ],
            name: 'StakingCapSet',
            type: 'event',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_addy',
                    type: 'address',
                },
            ],
            name: 'canWithdraw',
            outputs: [
                {
                    internalType: 'bool',
                    name: '',
                    type: 'bool',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_address',
                    type: 'address',
                },
            ],
            name: 'changeRewardAddress',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [],
            name: 'depositTime',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address',
                },
            ],
            name: 'deposited',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'emergencyWithdraw',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [],
            name: 'emergencyWithdrawAdmin',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [],
            name: 'name',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'owner',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'renounceOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [],
            name: 'rewardAddress',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'rewardPerc',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'setDepositEnabled',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '_depositTime',
                    type: 'uint256',
                },
            ],
            name: 'setDepositTime',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '_rewardPerc',
                    type: 'uint256',
                },
            ],
            name: 'setRewardPerc',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '_stakingCap',
                    type: 'uint256',
                },
            ],
            name: 'setStakingCap',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                },
            ],
            name: 'stake',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'account',
                    type: 'address',
                },
            ],
            name: 'stakeOf',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'stakedTotal',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'stakingCap',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'stakingEnds',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'stakingStarts',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'account',
                    type: 'address',
                },
            ],
            name: 'timeStaked',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address',
                },
            ],
            name: 'transferOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [],
            name: 'withdraw',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
    ],
    vestingABI: [
        {
            inputs: [
                {
                    internalType: 'string',
                    name: '_name',
                    type: 'string',
                },
                {
                    internalType: 'address',
                    name: '_tokenAddress',
                    type: 'address',
                },
                {
                    internalType: 'uint256',
                    name: '_totalVest',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: '_vestingDays',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: '_initialValue',
                    type: 'uint256',
                },
            ],
            stateMutability: 'nonpayable',
            type: 'constructor',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'recipient',
                    type: 'address',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'recipientTotalShare',
                    type: 'uint256',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'recipientInitialShare',
                    type: 'uint256',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'recipientDailyShare',
                    type: 'uint256',
                },
            ],
            name: 'LogRecipientAdded',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'setter',
                    type: 'address',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'startDate',
                    type: 'uint256',
                },
            ],
            name: 'LogStartDateSet',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: false,
                    internalType: 'address',
                    name: 'recipient',
                    type: 'address',
                },
                {
                    indexed: false,
                    internalType: 'uint256',
                    name: 'amount',
                    type: 'uint256',
                },
            ],
            name: 'LogTokensClaimed',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'previousOwner',
                    type: 'address',
                },
                {
                    indexed: true,
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address',
                },
            ],
            name: 'OwnershipTransferred',
            type: 'event',
        },
        {
            inputs: [
                {
                    internalType: 'address[]',
                    name: '_recipients',
                    type: 'address[]',
                },
                {
                    internalType: 'uint256[]',
                    name: '_recipientTotalShares',
                    type: 'uint256[]',
                },
            ],
            name: 'addMultipleRecipients',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '_recipientAddress',
                    type: 'address',
                },
                {
                    internalType: 'uint256',
                    name: '_recipientTotalShare',
                    type: 'uint256',
                },
            ],
            name: 'addRecipient',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [],
            name: 'claim',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [],
            name: 'hasClaim',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'initialValue',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'name',
            outputs: [
                {
                    internalType: 'string',
                    name: '',
                    type: 'string',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'owner',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: 'period',
                    type: 'uint256',
                },
                {
                    components: [
                        {
                            internalType: 'uint256',
                            name: 'withdrawnAmount',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'recipientTotalShare',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'recipientInitialShare',
                            type: 'uint256',
                        },
                        {
                            internalType: 'uint256',
                            name: 'recipientDailyShare',
                            type: 'uint256',
                        },
                    ],
                    internalType: 'struct SwashVesting.Recipient',
                    name: 'recipient',
                    type: 'tuple',
                },
            ],
            name: 'percDiv',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'pure',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            name: 'recipientList',
            outputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: '',
                    type: 'address',
                },
            ],
            name: 'recipients',
            outputs: [
                {
                    internalType: 'uint256',
                    name: 'withdrawnAmount',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: 'recipientTotalShare',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: 'recipientInitialShare',
                    type: 'uint256',
                },
                {
                    internalType: 'uint256',
                    name: 'recipientDailyShare',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'renounceOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'uint256',
                    name: '_startDate',
                    type: 'uint256',
                },
            ],
            name: 'setStartDate',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [],
            name: 'startDate',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'totalRecipients',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'totalShares',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [],
            name: 'totalVest',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
        {
            inputs: [
                {
                    internalType: 'address',
                    name: 'newOwner',
                    type: 'address',
                },
            ],
            name: 'transferOwnership',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            inputs: [],
            name: 'vestingDays',
            outputs: [
                {
                    internalType: 'uint256',
                    name: '',
                    type: 'uint256',
                },
            ],
            stateMutability: 'view',
            type: 'function',
        },
    ],
    teamAndFundersVestingABI: [
        {
            "inputs": [],
            "stateMutability": "nonpayable",
            "type": "constructor"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "recipient",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "recipientTotalShare",
                    "type": "uint256"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "recipientInitialShare",
                    "type": "uint256"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "recipientDailyShare",
                    "type": "uint256"
                }
            ],
            "name": "LogRecipientAdded",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": false,
                    "internalType": "address",
                    "name": "recipient",
                    "type": "address"
                },
                {
                    "indexed": false,
                    "internalType": "uint256",
                    "name": "amount",
                    "type": "uint256"
                }
            ],
            "name": "LogTokensClaimed",
            "type": "event"
        },
        {
            "anonymous": false,
            "inputs": [
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "previousOwner",
                    "type": "address"
                },
                {
                    "indexed": true,
                    "internalType": "address",
                    "name": "newOwner",
                    "type": "address"
                }
            ],
            "name": "OwnershipTransferred",
            "type": "event"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_recipientAddress",
                    "type": "address"
                }
            ],
            "name": "activateRecipient",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address[]",
                    "name": "_recipients",
                    "type": "address[]"
                },
                {
                    "internalType": "uint256[]",
                    "name": "_recipientTotalShares",
                    "type": "uint256[]"
                },
                {
                    "internalType": "uint256[]",
                    "name": "_startDates",
                    "type": "uint256[]"
                }
            ],
            "name": "addMultipleRecipients",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_recipientAddress",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "_recipientTotalShare",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "_startDate",
                    "type": "uint256"
                }
            ],
            "name": "addRecipient",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "claim",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_recipientAddress",
                    "type": "address"
                }
            ],
            "name": "getStartDate",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "hasClaim",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "initialValue",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "string",
                    "name": "_name",
                    "type": "string"
                },
                {
                    "internalType": "address",
                    "name": "_tokenAddress",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "_totalVest",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "_vestingDays",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "_initialValue",
                    "type": "uint256"
                }
            ],
            "name": "initialize",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "name",
            "outputs": [
                {
                    "internalType": "string",
                    "name": "",
                    "type": "string"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "owner",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "period",
                    "type": "uint256"
                },
                {
                    "components": [
                        {
                            "internalType": "uint256",
                            "name": "withdrawnAmount",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "recipientTotalShare",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "recipientInitialShare",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "recipientDailyShare",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "startDate",
                            "type": "uint256"
                        },
                        {
                            "internalType": "uint256",
                            "name": "revokedDate",
                            "type": "uint256"
                        }
                    ],
                    "internalType": "struct SwashTeamVestingUpgradable.Recipient",
                    "name": "recipient",
                    "type": "tuple"
                }
            ],
            "name": "percDiv",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "name": "recipientList",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "name": "recipients",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "withdrawnAmount",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "recipientTotalShare",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "recipientInitialShare",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "recipientDailyShare",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "startDate",
                    "type": "uint256"
                },
                {
                    "internalType": "uint256",
                    "name": "revokedDate",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "renounceOwnership",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "_recipientAddress",
                    "type": "address"
                },
                {
                    "internalType": "uint256",
                    "name": "_revokedDate",
                    "type": "uint256"
                }
            ],
            "name": "revokeRecipient",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "token",
            "outputs": [
                {
                    "internalType": "address",
                    "name": "",
                    "type": "address"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "totalRecipients",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "totalShares",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "totalVest",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        },
        {
            "inputs": [
                {
                    "internalType": "address",
                    "name": "newOwner",
                    "type": "address"
                }
            ],
            "name": "transferOwnership",
            "outputs": [],
            "stateMutability": "nonpayable",
            "type": "function"
        },
        {
            "inputs": [],
            "name": "vestingDays",
            "outputs": [
                {
                    "internalType": "uint256",
                    "name": "",
                    "type": "uint256"
                }
            ],
            "stateMutability": "view",
            "type": "function"
        }
    ],
    erc20ABI: [
        {
            constant: false,
            inputs: [
                {
                    name: '_spender',
                    type: 'address',
                },
                {
                    name: '_value',
                    type: 'uint256',
                },
            ],
            name: 'approve',
            outputs: [
                {
                    name: 'success',
                    type: 'bool',
                },
            ],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            constant: false,
            inputs: [
                {
                    name: '_to',
                    type: 'address',
                },
                {
                    name: '_value',
                    type: 'uint256',
                },
            ],
            name: 'showMeTheMoney',
            outputs: [],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            constant: false,
            inputs: [
                {
                    name: '_to',
                    type: 'address',
                },
                {
                    name: '_value',
                    type: 'uint256',
                },
            ],
            name: 'transfer',
            outputs: [
                {
                    name: 'success',
                    type: 'bool',
                },
            ],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            constant: false,
            inputs: [
                {
                    name: '_from',
                    type: 'address',
                },
                {
                    name: '_to',
                    type: 'address',
                },
                {
                    name: '_value',
                    type: 'uint256',
                },
            ],
            name: 'transferFrom',
            outputs: [
                {
                    name: 'success',
                    type: 'bool',
                },
            ],
            payable: false,
            stateMutability: 'nonpayable',
            type: 'function',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    name: '_from',
                    type: 'address',
                },
                {
                    indexed: true,
                    name: '_to',
                    type: 'address',
                },
                {
                    indexed: false,
                    name: '_value',
                    type: 'uint256',
                },
            ],
            name: 'Transfer',
            type: 'event',
        },
        {
            anonymous: false,
            inputs: [
                {
                    indexed: true,
                    name: '_owner',
                    type: 'address',
                },
                {
                    indexed: true,
                    name: '_spender',
                    type: 'address',
                },
                {
                    indexed: false,
                    name: '_value',
                    type: 'uint256',
                },
            ],
            name: 'Approval',
            type: 'event',
        },
        {
            constant: true,
            inputs: [
                {
                    name: '_owner',
                    type: 'address',
                },
                {
                    name: '_spender',
                    type: 'address',
                },
            ],
            name: 'allowance',
            outputs: [
                {
                    name: 'remaining',
                    type: 'uint256',
                },
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [
                {
                    name: '_owner',
                    type: 'address',
                },
            ],
            name: 'balanceOf',
            outputs: [
                {
                    name: 'balance',
                    type: 'uint256',
                },
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [],
            name: 'decimals',
            outputs: [
                {
                    name: '',
                    type: 'uint256',
                },
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [],
            name: 'name',
            outputs: [
                {
                    name: '',
                    type: 'string',
                },
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [],
            name: 'symbol',
            outputs: [
                {
                    name: '',
                    type: 'string',
                },
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
        {
            constant: true,
            inputs: [],
            name: 'totalSupply',
            outputs: [
                {
                    name: '',
                    type: 'uint256',
                },
            ],
            payable: false,
            stateMutability: 'view',
            type: 'function',
        },
    ],
    staking:[
        {
            stakeId:0,
            type:'staking',
            networkId:4,
            staking_title:'Old staking',
            tokenAddress:'0x105D3139f5A76A2Bf1c19E9065194343F31780c1',
            REACT_APP_STAKING_FINISH_DATE:'01-30-2022',
            REACT_APP_STAKING_ADDRESS:'0x4B8770A97F44a055473C52088Ccd1234f874586b',
            REACT_APP_POOL_ADDRESS:'',
            startTime:'',
            endTime:'',
            refundee:'',

        },
        {
            stakeId:1,
            type:'staking',
            networkId:4,
            staking_title:'New staking',
            tokenAddress:'0x60c8e3d34e26d8749d52d7655a0d8647d96269a2',
            REACT_APP_STAKING_FINISH_DATE:'11-28-2021',
            REACT_APP_STAKING_ADDRESS:'0xEf54861F779Bcfd2b59aC16A6093B0E1578a8F86',
            REACT_APP_POOL_ADDRESS:'',
            startTime:'',
            endTime:'',
            refundee:'',
        },
        {
            stakeId:2,
            type:'liquidity',
            networkId:4,
            staking_title:'Swash liquidity staking',
            tokenAddress:'0x60c8e3d34e26d8749d52d7655a0d8647d96269a2',
            REACT_APP_STAKING_FINISH_DATE:'11-28-2021',
            REACT_APP_STAKING_ADDRESS:'0x1f98407aaB862CdDeF78Ed252D6f557aA5b0f00d',
            REACT_APP_POOL_ADDRESS:'0x71b5d3a034d7a272237193def64f04cb3fe8eef9',
            startTime:'1644881000',
            endTime:'1644891000',
            refundee:'0x37140f96Aa875D4364eA8C3B8655eD9e16612940',
        },
        {
            stakeId:3,
            type:'liquidity',
            networkId:4,
            staking_title:' New Swash liquidity staking',
            tokenAddress:'0x60c8e3d34e26d8749d52d7655a0d8647d96269a2',
            REACT_APP_STAKING_FINISH_DATE:'11-28-2021',
            REACT_APP_STAKING_ADDRESS:'0x1f98407aaB862CdDeF78Ed252D6f557aA5b0f00d',
            REACT_APP_POOL_ADDRESS:'0x71b5d3a034d7a272237193def64f04cb3fe8eef9',
            startTime:'1644960600',
            endTime:'1645003800',
            refundee:'0x37140f96Aa875D4364eA8C3B8655eD9e16612940',
        },
        {
            stakeId:4,
            type:'liquidity',
            networkId:4,
            staking_title:'New 2 Swash liquidity staking',
            tokenAddress:'0x60c8e3d34e26d8749d52d7655a0d8647d96269a2',
            REACT_APP_STAKING_FINISH_DATE:'11-28-2021',
            REACT_APP_STAKING_ADDRESS:'0x1f98407aaB862CdDeF78Ed252D6f557aA5b0f00d',
            REACT_APP_POOL_ADDRESS:'0x71b5d3a034d7a272237193def64f04cb3fe8eef9',
            startTime:'1644926709',
            endTime:'1644930309',
            refundee:'0x37140f96Aa875D4364eA8C3B8655eD9e16612940',
        },
        {
            stakeId:5,
            type:'liquidity',
            networkId:4,
            staking_title:'New 3 Swash liquidity staking',
            tokenAddress:'0x60c8e3d34e26d8749d52d7655a0d8647d96269a2',
            REACT_APP_STAKING_FINISH_DATE:'11-28-2021',
            REACT_APP_STAKING_ADDRESS:'0x1f98407aaB862CdDeF78Ed252D6f557aA5b0f00d',
            REACT_APP_POOL_ADDRESS:'0x71b5d3a034d7a272237193def64f04cb3fe8eef9',
            startTime:'1644938442',
            endTime:'1644942042',
            refundee:'0x37140f96Aa875D4364eA8C3B8655eD9e16612940',
        },
        {
            stakeId:0,
            type:'staking',
            networkId:1,
            staking_title:'Native Staking on Mainnet',
            tokenAddress:'0xA130E3a33a4d84b04c3918c4E5762223Ae252F80',
            REACT_APP_STAKING_FINISH_DATE:'07-15-2022',
            REACT_APP_STAKING_ADDRESS:'0x048F1D450E4cB558657F0998fA0375Fa967c4005',
            REACT_APP_POOL_ADDRESS:'',
            startTime:'',
            endTime:'',
            refundee:'',
        },
        {
            stakeId:1,
            type:'staking',
            networkId:1,
            staking_title:'Native Staking on Mainnet (Closed)',
            tokenAddress:'0xA130E3a33a4d84b04c3918c4E5762223Ae252F80',
            REACT_APP_STAKING_FINISH_DATE:'11-28-2021',
            REACT_APP_STAKING_ADDRESS:'0x596f867793a6DF0898B1967058fd03a95941fb78',
            REACT_APP_POOL_ADDRESS:'',
            startTime:'',
            endTime:'',
            refundee:'',
        },
        {
            stakeId:0,
            type:'staking',
            networkId:137,
            staking_title:'Native Staking on Polygon',
            tokenAddress:'0xbA3CB8329D442E6F9Eb70fafe1E214251df3D275',
            REACT_APP_STAKING_FINISH_DATE:'07-15-2022',
            REACT_APP_STAKING_ADDRESS:'0x78fc309A5561CF98592C8F2d8802EA482e3637D8',
            REACT_APP_POOL_ADDRESS:'',
            startTime:'',
            endTime:'',
            refundee:'',
        },
        {
            stakeId:0,
            type:'staking',
            networkId:100,
            staking_title:'Native Staking on xDai',
            tokenAddress:'0x84E2C67CBEfae6B5148fcA7d02B341B12ff4b5Bb',
            REACT_APP_STAKING_FINISH_DATE:'07-15-2022',
            REACT_APP_STAKING_ADDRESS:'0x37Fd954301EE82829C7cc9B4f5F73c1b5F364076',
            REACT_APP_POOL_ADDRESS:'',
            startTime:'',
            endTime:'',
            refundee:'',
        },
        {
            stakeId:0,
            type:'staking',
            networkId:56,
            staking_title:'Native Staking on Bsc',
            tokenAddress:'0x41065E3428188bA6Eb27FBDDE8526aE3Af8E3830',
            REACT_APP_STAKING_FINISH_DATE:'07-27-2023',
            REACT_APP_STAKING_ADDRESS:'0x68be31378890bA6f4B0cfA277379C18D9248Acec',
            REACT_APP_POOL_ADDRESS:'',
            startTime:'',
            endTime:'',
            refundee:'',
        }
    ],

};

export default config;

import styles from './label-text.module.css';
import {Divider} from '@mui/material';

function LabelText(props: {
    label: string;
    text: string;
    showDelimiter?: boolean;
}) {
    const showDelimiter =
        props.showDelimiter === undefined ? true : props.showDelimiter;

    return (
        <div >
            <div className={`${styles.container}`}>
                <div className={`${styles.label}`}>{props.label}</div>
                <div className={`${styles.text}`}>{props.text}</div>
            </div>
            {showDelimiter ? (
                <div className={`${styles.labelTextDivider}`}>
                    <Divider/>
                </div>
            ) : (
                <div/>
            )}
        </div>
    );
}

export default LabelText;

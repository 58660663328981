import uniSwapImage from '../../images/uni-swap.svg';
import swashImage from '../../images/swash-with-text.svg';
import connectImage from '../../images/connect.svg';
import rightImage from '../../images/arrow-right.svg';
import {Button, IconButton} from '@mui/material';
import styles from './header.module.css';
import {history} from '../../_helpers/history';
import React, {useContext, useEffect, useState} from 'react';
import ConnectionDialog from '../connection-dialog/connection-dialog';
import {useWeb3React} from '@web3-react/core';
import config from '../../config/config';
import {LoadingContext} from "../../context/loader";
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';

function Header() {
    const [currentPath, setCurrentPath] = useState('');
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        open && (document.body.style.overflow = 'hidden');
        !open && (document.body.style.overflow = 'unset');
    }, [open]);

    const [connectionDialogOpen, setConnectionDialogOpen] = React.useState(false);
    const {setRefresh} = useContext(LoadingContext);

    const handleConnectionDialogOpen = () => {
        setConnectionDialogOpen(true);
    };

    const handleConnectionDialogClose = () => {
        setConnectionDialogOpen(false);
    };

    useEffect(() => {
        activateProvider();
        setCurrentPath(window.location.pathname)
    }, [open]);

    let {account, activate, active} = useWeb3React();
    return (
        <div>
            <ConnectionDialog
                open={connectionDialogOpen}
                handleConnectionDialogClose={handleConnectionDialogClose}
            />
            <div className={`${styles.container}`}>
                <div className={`${styles.image}`} onClick={() => {
                    window.open('https://swashapp.io');
                }
                }>
                    <IconButton>
                        <img alt="" src={swashImage}/>
                    </IconButton>
                </div>
                <div className={`${styles.allButtons}`}>
                    <div className={`${styles.uniSwapButton}`} onClick={() =>
                        window.open('https://app.uniswap.org/#/add/0xa130e3a33a4d84b04c3918c4e5762223ae252f80/0xdac17f958d2ee523a2206206994597c13d831ec7/3000')
                    }>
                        <Button className={`${styles.button}`}>
                            <div className={`${styles.uniSwapButtonIcon}`}>
                                <img alt="" src={uniSwapImage}/>
                            </div>
                            <div className={`${styles.uniSwapButtonText}`}>
                                {'Uniswap | Swash <> USDT/ETH'}
                            </div>
                        </Button>
                    </div>
                    <div className={`${styles.connectButton}`} onClick={handleConnectionDialogOpen}>
                        <Button
                            title={account === undefined || account === null ? '' : account}
                            className={`${styles.button}`}
                        >
                            <div className={`${styles.connectButtonIcon}`}>
                                <img alt="" src={connectImage}/>
                            </div>
                            <div className={`${styles.connectButtonText}`}>
                                {account === undefined || account === null
                                    ? 'Connect Wallet'
                                    : account
                                        ? `${account.substring(0, 6)}...${account.substring(
                                            account.length - 4,
                                        )}`
                                        : ''}
                            </div>
                        </Button>
                    </div>
                    <div className={`${styles.stakeButton}`} onClick={() => {
                        setRefresh('');
                        history.push(`${(currentPath !== '/stake' && currentPath !== '/') ? '/stake' : '/tutorial'}`);
                    }}>
                        <Button
                            className={`${styles.button}`}
                            onClick={() => {
                                setCurrentPath((currentPath !== '/stake' && currentPath !== '/') ? '/stake' : '/tutorial');
                            }}>
                            <div
                                className={`${styles.stakeText}`}>{(currentPath !== '/stake' && currentPath !== '/') ? 'STAKE' : 'Tutorial Videos'}</div>
                            <div className={`${styles.stakeIcon}`}>
                                <img alt="" src={rightImage}/>
                            </div>
                        </Button>
                    </div>
                    <div className={`${styles.claimButton}`} onClick={() => {
                        setRefresh('');
                        history.push(`${currentPath !== '/claim' ? '/claim' : '/tutorial'}`);
                    }}>
                        <Button className={`${styles.button}`}
                                onClick={() => {
                                    setCurrentPath(currentPath !== '/claim' ? '/claim' : '/tutorial');
                                }}>
                            <div
                                className={`${styles.claimText}`}>{currentPath !== '/claim' ? 'CLAIM' : 'Tutorial Videos'}</div>
                            <div className={`${styles.claimIcon}`}>
                                <img alt="" src={rightImage}/>
                            </div>
                        </Button>
                    </div>
                </div>
                <div
                    className={styles.menu}
                    onClick={() => {
                        setOpen(!open);
                    }}
                >
                    {open ? (
                        <CloseIcon fontSize={'large'}/>
                    ) : (
                        <MenuIcon fontSize={'large'}/>
                    )}
                </div>
                {open ? (
                    <div className={styles.dropdown}>
                        <div className={`${styles.uniSwapButton} ${styles.maxWidth}`} onClick={() =>
                            window.open(
                                "https://app.uniswap.org",
                                "_blank"
                            )
                        }>
                            <Button className={`${styles.button}`}>
                                <div className={`${styles.uniSwapButtonIcon}`}>
                                    <img alt="" src={uniSwapImage}/>
                                </div>
                                <div className={`${styles.uniSwapButtonText}`}>
                                    {'Uniswap | Swash <> USDT/ETH'}
                                </div>
                            </Button>
                        </div>
                        <div className={`${styles.connectButton} ${styles.maxWidth}`}
                             onClick={handleConnectionDialogOpen}>
                            <Button
                                title={account === undefined || account === null ? '' : account}
                                className={`${styles.button}`}
                            >
                                <div className={`${styles.connectButtonIcon}`}>
                                    <img alt="" src={connectImage}/>
                                </div>
                                <div className={`${styles.connectButtonText}`}>
                                    {account === undefined || account === null
                                        ? 'Connect Wallet'
                                        : account
                                            ? `${account.substring(0, 6)}...${account.substring(
                                                account.length - 4,
                                            )}`
                                            : ''}
                                </div>
                            </Button>
                        </div>
                        <div className={`${styles.stakeButton} ${styles.maxWidth}`} onClick={() => {
                            setRefresh('');
                            history.push(`${(currentPath !== '/stake' && currentPath !== '/') ? '/stake' : '/tutorial'}`);
                        }}>
                            <Button className={`${styles.button} ${styles.buttonInner}`}
                            >
                                <div
                                    className={`${styles.stakeText}`}>{(currentPath !== '/stake' && currentPath !== '/') ? 'STAKE' : 'Tutorial Videos'}</div>
                                <div className={`${styles.stakeIcon}`}>
                                    <img alt="" src={rightImage}/>
                                </div>
                            </Button>
                        </div>
                        <div className={`${styles.claimButton} ${styles.maxWidth}`} onClick={() => {
                            setRefresh('');
                            history.push(`${currentPath !== '/claim' ? '/claim' : '/tutorial'}`);
                        }}>
                            <Button className={`${styles.button} ${styles.buttonInner}`}>
                                <div
                                    className={`${styles.claimText}`}>{currentPath !== '/claim' ? 'CLAIM' : 'Tutorial Videos'}</div>
                                <div className={`${styles.claimIcon}`}>
                                    <img alt="" src={rightImage}/>
                                </div>
                            </Button>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );

    function activateProvider() {
        const currentProvider = localStorage.getItem('currentProvider');
        const walletconnect = localStorage.getItem('walletconnect');

        console.log('====== cookie =======')
        console.log(currentProvider)
        console.log(walletconnect)
        console.log(active)

        if (currentProvider === undefined || currentProvider === null) {
            return;
        }
        let provider = null;
        if (currentProvider === 'Metamask') {
            provider = config.connectors.Injected;
            if (provider != null) {
                if (account === undefined) {
                    activate(provider,  undefined, true);
                }
            }
        } else if (currentProvider === 'WalletConnect') {
            provider = config.connectors.WalletConnect;
            if(!active){
                activate(provider);
            }
        } else if (currentProvider === 'Ledger') {
            provider = config.connectors.Ledger;
            if (provider != null) {
                if (account === undefined) {
                    activate(provider,  undefined, true);
                }
            }
        }

    }
}

export default Header;

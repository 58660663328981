import {Button, Divider} from '@mui/material';
import LabelText from '../label-text/label-text';
import swapImage from '../../images/swash.svg';

import styles from './liquidity-mining.module.css';
import {useWeb3React} from '@web3-react/core';
import React, {useContext, useEffect} from 'react';
import {LoadingContext, LoadingItem} from '../../context/loader';
import {Web3Provider} from '@ethersproject/providers';
import {MessageContext} from "../../context/messaging";
import {UniSwapV3Service} from "../../services/UniSwapV3Staker";
import LiquidityStakeDialog from "../liquidity-stake-dialog/liquidity-stake-dialog";
import LiquidityStakedDialog from "../liquidity-staked-dialog/liquidity-staked-dialog";

function LiquidityMining(props: any) {
    const {library, account} = useWeb3React();
    const {refresh, loadingData, setLoadingData, setRefresh} = useContext(
        LoadingContext,
    );
    const [liquidityStakeDialogOpen, setLiquidityStakeDialogOpen] = React.useState(false);
    const [liquidityStakedDialogOpen, setLiquidityStakedDialogOpen] = React.useState(false);
    const [stakeEnable, setStakeEnable] = React.useState(false);
    const [unStakeEnable, setUnStakeEnable] = React.useState(false);
    const [claimEnable, setClaimEnable] = React.useState(false);

    const {
        setMessageOpen,
        setSeverity,
        setMessageText
    } = useContext(MessageContext);

    useEffect(() => {
        fetchData(account, library);
    }, [account, refresh]);
    const [stakeDialogData, setStakeDialogData] = React.useState([]);
    const [stakedDialogData, setStakedDialogData] = React.useState({
        allStakedTokens: new Array<{ rewardPretty: string, isStaked: boolean }>(),
        totalRewards: '',
        totalRewardsPretty: '',
    });

    const handleStakeDialogOpen = () => {
        loadingData.set('LiquidityMining-stakeLiquidity', {message: 'Loading Token Data For Staking...'});
        setLoadingData(new Map<string, LoadingItem>(loadingData));
        UniSwapV3Service(library).geNFTTokenData(account).then((data) => {
            // @ts-ignore
            setStakeDialogData(data);


            setStakeEnable(data.length > 0);
            loadingData.delete('LiquidityMining-stakeLiquidity');
            setLoadingData(new Map<string, LoadingItem>(loadingData));
            setLiquidityStakeDialogOpen(true);
        }).catch(() => {
            loadingData.delete('LiquidityMining-stakeLiquidity');
            setLoadingData(new Map<string, LoadingItem>(loadingData));
            setMessageOpen(true);
            setSeverity("error");
            setMessageText('Loading token data for staking failed.');
        });
    };

    const handleStakeDialogClose = () => {
        setLiquidityStakeDialogOpen(false);
    };
    const handleUnStakedDialogOpen = () => {
        loadingData.set('LiquidityMining-stakedLiquidity', {message: 'Loading Staked Data...'});
        setLoadingData(new Map<string, LoadingItem>(loadingData));
        UniSwapV3Service(library).geStakedData(account).then((data) => {
            // @ts-ignore
            setStakedDialogData(data);
            setUnStakeEnable(data.allStakedTokens.length > 0);
            loadingData.delete('LiquidityMining-stakedLiquidity');
            setLoadingData(new Map<string, LoadingItem>(loadingData));
            setLiquidityStakedDialogOpen(true);
        }).catch(() => {
            loadingData.delete('LiquidityMining-stakedLiquidity');
            setLoadingData(new Map<string, LoadingItem>(loadingData));
            setMessageOpen(true);
            setSeverity("error");
            setMessageText('Loading staked data failed.');
        });
    };

    const handleUnStakeDialogClose = () => {
        setLiquidityStakedDialogOpen(false);
    };
    const doClaim = async (totalRewards: string) => {
        loadingData.set('LiquidityMining-ClaimLiquidity', {message: 'Claiming...'});
        setLoadingData(new Map<string, LoadingItem>(loadingData));
        let hasError = false;
        let tx = await UniSwapV3Service(library).claimReward(account, totalRewards).catch((error) => {
            console.log(error);
            setMessageOpen(true);
            setSeverity("error");
            setMessageText('Claiming failed.');
            hasError = true;
        });
        if (tx !== undefined) {
            await tx.wait().catch((error: any) => {
                console.log(error);
                hasError = true;
                setMessageOpen(true);
                setSeverity("error");
                setMessageText('Claiming failed.');
            });
        }
        loadingData.delete('LiquidityMining-ClaimLiquidity');
        setLoadingData(new Map<string, LoadingItem>(loadingData));
        if (!hasError) {
            setRefresh('LiquidityMining_' + new Date().getTime());
            setMessageOpen(true);
            setSeverity("success");
            setMessageText('Claiming successfully done.');
        }
    }

    const getStakedCountTitle = () => {
        let count = 0;
        for (const stakedItem of stakedDialogData.allStakedTokens) {
            if (stakedItem.isStaked) {
                count++;
            }
        }
        return count > 1 ? count + ' Positions' : count + ' Position';
    }

    const getTotalReward = () => {
        let sumOfRewards = 0;
        for (const stakedItem of stakedDialogData.allStakedTokens) {
            sumOfRewards += Number(stakedItem.rewardPretty);
        }
        sumOfRewards += Number(stakedDialogData.totalRewards)
        return sumOfRewards.toString();
    }

    return (
        <div >
            <LiquidityStakeDialog
                open={liquidityStakeDialogOpen}
                data={stakeDialogData}
                handleStakeDialogClose={handleStakeDialogClose}
            />
            <LiquidityStakedDialog
                open={liquidityStakedDialogOpen}
                data={stakedDialogData}
                handleUnStakeDialogClose={handleUnStakeDialogClose}
            />

            <div className={`${styles.apyContainer}`}>
                <div className={`${styles.apyLabel}`}>APY</div>
                <div className={`${styles.apyText}`}>TBA%</div>
            </div>
            <div className={`${styles.labelTextContainer}`}>
                <LabelText label="Type" text="Swash Liquidity Mining"/>
            </div>
            <div className={`${styles.labelTextContainer}`}>
                <LabelText label="Full Maturity Period" text="Time Based"/>
            </div>
            <div className={`${styles.labelTextContainer}`}>
                <LabelText label="Pool Size" text="Unlimited"/>
            </div>
            <div className={`${styles.labelTextContainer}`}>
                <LabelText label="Pool Status" text="OPEN"/>
            </div>
            <div className={`${styles.labelTextContainer}`}>
                <LabelText
                    label="Your Stake"
                    text={getStakedCountTitle()}
                />
            </div>
            <div className={`${styles.labelTextContainer}`}>
                <LabelText
                    label="Claimable Reward"
                    text={'' + Number(stakedDialogData.totalRewardsPretty)}
                />
            </div>
            <div className={`${styles.labelTextContainer}`}>
                <LabelText
                    label="Total Reward"
                    text={getTotalReward()}
                />
            </div>
            <div className={`${styles.divider}`}>
                <Divider/>
            </div>
            <div className={`${styles.actionButtons}`}>
                <div className={stakeEnable ? `${styles.stakeButtonEnable}`
                    : `${styles.stakeButtonDisable}`}>
                    <Button
                        disabled={!stakeEnable}
                        className={`${styles.stakeButtonText}`}
                        onClick={() => {
                            handleStakeDialogOpen();
                        }}
                    >
                        Stake Now
                    </Button>
                </div>
                <div className={unStakeEnable ? `${styles.withdrawButtonEnable}`
                    : `${styles.withdrawButtonDisable}`}>
                    <Button
                        disabled={!unStakeEnable}
                        className={`${styles.withdrawButtonText}`}
                        onClick={() => {
                            handleUnStakedDialogOpen();
                        }}
                    >
                        Unstake/Withdraw
                    </Button>
                </div>
                <div className={claimEnable ? `${styles.claimButtonEnable}` : `${styles.claimButtonDisable}`}>
                    <Button className={`${styles.claimButtonText}`}
                            disabled={!claimEnable}
                            onClick={() => {
                                doClaim(stakedDialogData.totalRewards).then();
                            }
                            }
                    >Claim</Button>
                </div>
            </div>
        </div>
    );

    async function fetchData(
        account: string | null | undefined,
        provider: Web3Provider,
    ) {
        if (refresh !== '' && !refresh.includes('LiquidityMining_')) {
            return;
        }
        if (account === undefined || provider === undefined) {
            return;
        }
        if (provider !== undefined) {
            const correctNetwork = process.env.REACT_APP_NETWORK_ID || '1';
            const network = await provider.getNetwork();
            if ('' + network.chainId !== correctNetwork) {
                return;
            }
        }
        try {
            loadingData.set('LiquidityStakingLoadData', {message: 'Loading staking info'});
            setLoadingData(new Map<string, LoadingItem>(loadingData));
            const stakedData = await UniSwapV3Service(provider).geStakedData(account);
            // @ts-ignore
            setStakedDialogData(stakedData);
            setUnStakeEnable(stakedData.allStakedTokens.length > 0);
            setClaimEnable(Number(stakedData.totalRewards) > 0);

            const tokenData = await UniSwapV3Service(provider).geNFTTokenData(account);
            // @ts-ignore
            setStakeDialogData(tokenData);
            setStakeEnable(tokenData.length > 0);
            loadingData.delete('LiquidityStakingLoadData');
            setLoadingData(new Map<string, LoadingItem>(loadingData));
        } catch (exception) {
            loadingData.delete('LiquidityStakingLoadData');
            setLoadingData(new Map<string, LoadingItem>(loadingData));
            setMessageOpen(true);
            setSeverity("error");
            setMessageText('Loading staking data failed.');
        }
    }
}

export default LiquidityMining;

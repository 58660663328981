import './App.css';
import '../src/styles/main.css';
import {Route, Router} from 'react-router-dom';
import Claim from './pages/claim/claim';
import SwashHeader from './components/header/header';
import Footer from './components/footer/footer';
import Staking from './pages/staking/staking';
import {history} from './_helpers/history';
import React from 'react';
import {LoadingProvider} from './context/loader';
import {Web3ReactProvider} from '@web3-react/core';
import {Web3Provider} from '@ethersproject/providers';
import LoadingDialog from './components/loading-dialog/loading-dialog';
import {MessageProvider} from "./context/messaging";
import Message from "./components/message/message";
import Tutorials from "./pages/tutorials/tutorials";
import Claim2 from "./pages/claim2/claim2";

function App() {
    const [networkId, setNetworkId] = React.useState('-1');

    function getLibrary(provider: any): Web3Provider {
        let library: Web3Provider;

        library = new Web3Provider(provider);
        library.pollingInterval = 12000;
        library.getNetwork().then((network) => {
            setNetworkId('' + network.chainId);
        });
        return library;
    }

    return (
        <div className="app">
            <LoadingProvider>
                <MessageProvider>
                    <Web3ReactProvider getLibrary={getLibrary}>
                        <Router history={history}>
                            <SwashHeader/>
                            <div className="mainContainer">
                                <LoadingDialog/>
                                <Message/>
                                <div className="mainPanel">
                                    <div>
                                        <Route exact path="/" component={Staking}/>
                                        <Route exact path="/claim" component={Claim}/>
                                        <Route exact path="/claim-team" component={Claim2}/>
                                        <Route exact path="/tutorial" component={Tutorials}/>
                                        <Route path="/stake" component={Staking}/>
                                    </div>

                                    <div className="mainFooter">
                                        <Footer/>
                                    </div>
                                </div>
                            </div>
                        </Router>
                    </Web3ReactProvider>
                </MessageProvider>
            </LoadingProvider>
        </div>
    );
}

export default App;

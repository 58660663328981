import * as React from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import LinearProgress, {linearProgressClasses,} from '@mui/material/LinearProgress';

export default function SwashProgress(props: {
    borderRadius: number;
    height: number;
    value: number;
}) {
    const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
        height: props.height,
        borderRadius: props.borderRadius,
        border: 10,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: '#FFFFFF',
            border: 10,
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: props.borderRadius,
            backgroundColor: '#59F28B',
        },
    }));
    return (
        <Box
            sx={{
                borderRadius: props.borderRadius,
                padding: '1px',
                backgroundColor: '#59F28B',
            }}
        >
            <BorderLinearProgress variant="determinate" value={props.value}/>
        </Box>
    );
}

import {Web3Provider} from '@ethersproject/providers';
import React, {useContext, useEffect} from 'react';
import {SwashStakingService} from '../../services/SwashStakingService';
import {Button, Divider} from '@mui/material';
import LabelText from '../label-text/label-text';

import styles from './swash-staking.module.css';
import SwashProgress from '../bordered-linear-progress/swash-progress';
import {LoadingContext, LoadingItem} from '../../context/loader';
import {SwashService} from '../../services/SwashService';
import {useWeb3React} from '@web3-react/core';
import moment from "moment";
import {MessageContext} from "../../context/messaging";
import {formatEther} from "@ethersproject/units";

export class StakingOb {

    constructor(public stakeId: number,
                public type: string,
                public networkId: number,
                public tokenAddress: string,
                public staking_title: string,
                public startTime: string,
                public endTime: string,
                public REACT_APP_POOL_ADDRESS: string,
                public REACT_APP_STAKING_FINISH_DATE: string,
                public REACT_APP_STAKING_ADDRESS: string,
                public refundee: string) {
    }
}

function SwashStaking(props: any) {
    const {library, account} = useWeb3React();
    const {loadingData, refresh, setLoadingData, setRefresh} = useContext(
        LoadingContext,
    );
    const [dataModel, setDataModel] = React.useState({
        name: 'SwashStaking',
        type: '',
        poolStatus: '',
        stakedBalancePretty: '0',
        stakedBalance: 0,
        progress: '0',
        withdrawDate: 0,
        stakeApprove: false,
        balance: null,
        stakingDuration: 0,
        stakingCapacity: '',
        stakedTotal: '',
        stakingStarts: 0,
        stakingEnds: 0,
        rewardPerc: '0',
        timeStaked: 0,
        reward: '0',
    });

    const {
        setMessageOpen,
        setSeverity,
        setMessageText
    } = useContext(MessageContext);


    useEffect(() => {
        if (!library) {
            let model = {
                name: '',
                type: '',
                poolStatus: '',
                stakedBalancePretty: '0',
                stakedBalance: 0,
                progress: '0',
                withdrawDate: 0,
                stakeApprove: false,
                balance: null,
                stakingDuration: 0,
                stakingCapacity: '',
                stakedTotal: '',
                stakingStarts: 0,
                stakingEnds: 0,
                rewardPerc: '0',
                timeStaked: 0,
                reward: '0',
            };
            setDataModel(model)

            return;
        }
        fetchData(account, library);


    }, [account, library, refresh]);

    async function unStake() {
        loadingData.set('swash-unStake', {message: 'Unstaking...'});
        setLoadingData(new Map<string, LoadingItem>(loadingData));
        let hasError = false;

        let tx = await SwashStakingService(library).withdraw().catch((error) => {
            console.log(error);
            setMessageOpen(true);
            setSeverity("error");
            setMessageText('UnStaking failed.');
            hasError = true;
        });

        if (tx !== undefined) {
            await tx.wait().catch((error: any) => {
                console.log(error);
                setMessageOpen(true);
                setSeverity("error");
                setMessageText('UnStaking failed.');
                hasError = true;
            });
        }
        loadingData.delete('swash-unStake');
        setLoadingData(new Map<string, LoadingItem>(loadingData));
        if (!hasError) {
            setRefresh('SwashUnStaking_' + new Date().getTime());
            setMessageOpen(true);
            setSeverity("success");
            setMessageText('UnStaking successfully done.');
        }
    }

    function isUnStakeEnable() {
        const now = moment().unix();
        return dataModel.stakedBalance > 0 && dataModel.withdrawDate !== null && now > dataModel.withdrawDate;
    }

    function isStakeEnable() {
        console.log('isStakeEnable dataModel');
        console.log(dataModel);
        return Number(dataModel.balance) > 0 && dataModel.poolStatus === 'OPEN';
    }

    return (
        <div>
            <div className={`${styles.apyContainer}`}>
                <div className={`${styles.apyLabel}`}>APY</div>
                <div
                    className={`${styles.apyText}`}>{dataModel.stakingDuration === 0 ? 0 : Math.round(Number(dataModel.rewardPerc) * 365 / dataModel.stakingDuration)}%
                </div>
            </div>
            <div className={`${styles.labelTextContainer}`}>
                <LabelText
                    label="Type"
                    text="Native Token Staking"
                />
            </div>
            <div className={`${styles.labelTextContainer}`}>
                <LabelText
                    label="Full Maturity Period"
                    text={Math.trunc(dataModel.stakingDuration / 30) + ' Months'}
                />
            </div>
            <div className={`${styles.labelTextContainer}`}>
                <LabelText
                    label="Pool Size"
                    text={dataModel.stakingCapacity + ' Swash'}
                />
            </div>
            <div className={`${styles.labelTextContainer}`}>
                <LabelText
                    label="Remaining Size"
                    text={  Math.ceil(Number(dataModel.stakingCapacity) - Number(formatEther(BigInt(dataModel.stakedTotal)))) + ' Swash'}
                />
            </div>
            <div className={`${styles.labelTextContainer}`}>
                <LabelText label="Pool Status" text={dataModel.poolStatus}/>
            </div>
            <div className={`${styles.labelTextContainer}`}>
                <LabelText label="Your Stake" text={dataModel.stakedBalancePretty.toString()}/>
            </div>
            <div className={`${styles.labelTextContainer}`}>
                <LabelText label="Your Reward:" text={dataModel.reward}/>
            </div>
            <div className={`${styles.progress}`}>
                <SwashProgress
                    borderRadius={50}
                    height={32}
                    value={Number(dataModel.progress)}
                />
            </div>
            <div className={`${styles.progressFooter}`}>
                Progress {dataModel.progress} / 100%
            </div>
            <div className={`${styles.withdrawText}`}>
                {dataModel.withdrawDate === null || dataModel.withdrawDate === 0
                    ? ''
                    : 'Withdrawal date: ' + moment.unix(dataModel.withdrawDate).format('DD, MMMM, YYYY HH:mm')}
            </div>
            <div className={`${styles.lastDivider}`}>
                <Divider/>
            </div>
            <div className={`${styles.actionButtons}`}>
                <div
                    className={
                        isStakeEnable()
                            ? `${styles.stakeButtonEnable}`
                            : `${styles.stakeButtonDisable}`
                    }
                >
                    <Button
                        className={`${styles.buttonText}`}
                        onClick={() => {
                            props.handleStakingDialogOpen(dataModel);
                        }}
                        disabled={!isStakeEnable()}
                    >
                        Stake Now
                    </Button>
                </div>
                <div
                    className={
                        isUnStakeEnable()
                            ? `${styles.unStakeButtonEnable}`
                            : `${styles.unStakeButtonDisable}`
                    }
                >
                    <Button
                        className={`${styles.buttonText}`}
                        onClick={unStake}
                        disabled={!isUnStakeEnable()}
                    >
                        Unstake
                    </Button>
                </div>
            </div>
        </div>
    );

    async function fetchData(
        account: string | null | undefined,
        provider: Web3Provider,
    ) {
        if (refresh !== '' && !refresh.includes('SwashStaking_')) {
            return;
        }
        loadingData.set('SwashStakingLoadData', {message: 'Loading staking info'});
        setLoadingData(new Map<string, LoadingItem>(loadingData));

        let model = {
            name: 'SwashStaking',
            type: '',
            poolStatus: '',
            stakedBalancePretty: '0',
            stakedBalance: 0,
            progress: '0',
            withdrawDate: 0,
            stakeApprove: false,
            balance: null,
            stakingDuration: 0,
            stakingCapacity: '',
            stakedTotal: '',
            stakingStarts: 0,
            stakingEnds: 0,
            rewardPerc: '0',
            timeStaked: 0,
            reward: '0',
        };


        if (account === undefined) {
            console.log('no account');
            loadingData.delete('SwashStakingLoadData');
            setLoadingData(new Map<string, LoadingItem>(loadingData));
            setDataModel(model);
            return;
        }
        // if (provider !== undefined) {
        // const correctNetwork = process.env.REACT_APP_NETWORK_ID || '1';
        // const network = await provider.getNetwork();
        // if ('' + network.chainId !== correctNetwork) {
        //     loadingData.delete('SwashStakingLoadData');
        //     setLoadingData(new Map<string, LoadingItem>(loadingData));
        //     return;
        // }
        // }

        SwashService(provider)
            .getBalance(account)
            .then(balance => {
                model.balance = balance;
                SwashStakingService(provider)
                    .getStakedBalance(account)
                    .then(stakedBalance => {
                        model.stakedBalancePretty = stakedBalance.stakedBalancePretty;
                        model.stakedBalance = stakedBalance.stakedBalance;
                        let stakeBalanceNum = stakedBalance.stakedBalance;
                        SwashStakingService(provider)
                        SwashStakingService(provider)
                            .getTimeStaked(account)
                            .then(ts => {
                                model.progress = ts.progress;
                                model.withdrawDate = ts.withdrawDate as number;
                                SwashStakingService(provider)
                                    .checkApproval(account)
                                    .then(approval => {
                                        model.stakeApprove =
                                            approval == null ? false : approval;
                                        SwashStakingService(provider)
                                            .getContractInfo()
                                            .then(stakingInfo => {

                                                model.stakingDuration = stakingInfo.stakingDuration;
                                                model.stakingCapacity = stakingInfo.stakingCapacity;
                                                model.stakedTotal = stakingInfo.stakedTotal;
                                                model.stakingStarts = stakingInfo.stakingStarts;
                                                model.stakingEnds = stakingInfo.stakingEnds;
                                                model.rewardPerc = stakingInfo.rewardPerc;
                                                model.poolStatus = stakingInfo.poolStatus;
                                                const allRewards = stakeBalanceNum * (Number(model.rewardPerc)) / 100;
                                                const rewardTillNow = allRewards * Number(model.progress) / 100;
                                                model.reward = '' + Number(rewardTillNow.toPrecision(4));
                                                setDataModel(model);
                                                props.handleSetDialogData(model);
                                                loadingData.delete('SwashStakingLoadData');
                                                setLoadingData(new Map<string, LoadingItem>(loadingData));
                                            });
                                    });
                            });
                    });
            })
            .catch(err => {
                console.log(err.toString());
                console.log(err);
                loadingData.delete('SwashStakingLoadData');
                setLoadingData(new Map<string, LoadingItem>(loadingData));
            });
    }
}

export default SwashStaking;

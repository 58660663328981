import React from 'react';
import FoundersClaim from "../../components/founders-claim/founders-claim";
import TeamClaim from "../../components/team-claim/team-claim";

function Claim2() {
    return (
        <div className="contents">
            <TeamClaim/>
            <FoundersClaim/>
        </div>
    );
}

export default Claim2;

import {createContext, ReactChild, ReactFragment, ReactPortal, useMemo, useState,} from 'react';

export type MessageContent = {
    messageOpen: boolean;
    setMessageOpen: (c: boolean) => void;
    severity: string;
    setSeverity: (c: string) => void;
    messageText: string;
    setMessageText: (t: string) => void;
};

export const MessageContext = createContext<MessageContent>({
    messageOpen: false,
    setMessageOpen: (status: boolean) => {
    },
    severity: '',
    setSeverity: (status: string) => {
    },
    messageText: '',
    setMessageText: (text: string) => {
    },
});

export const MessageProvider = (props: {
    children:
        | boolean
        | ReactChild
        | ReactFragment
        | ReactPortal
        | null
        | undefined;
}) => {
    const [messageOpen, setMessageOpen] = useState(false);
    const [severity, setSeverity] = useState('');
    const [messageText, setMessageText] = useState('');

    const value = useMemo(
        () => {
            return ({
                messageOpen,
                setMessageOpen,
                severity,
                setSeverity,
                messageText,
                setMessageText,
            });
        },
        [messageOpen, severity, messageText],
    );

    return (
        <MessageContext.Provider value={value}>
            {props.children}
        </MessageContext.Provider>
    );
};

import {defaultAbiCoder} from "@ethersproject/abi";
import config from "../config/config";

const ranges = [
    {divider: 1e18, suffix: 'E'},
    {divider: 1e15, suffix: 'P'},
    {divider: 1e12, suffix: 'T'},
    {divider: 1e9, suffix: 'G'},
    {divider: 1e6, suffix: 'M'},
    {divider: 1e3, suffix: 'K'},
];

interface IncentiveKey {
    rewardToken: string,
    pool: string,
    startTime: number,
    endTime: number,
    refundee: string
}


function getRewardTokenAddress(): string{

    let stakeIdLocal: string | null = localStorage.getItem('stakeId')
    let stakeId = (stakeIdLocal != null ? parseInt(stakeIdLocal): 0)

    return config.staking[stakeId].tokenAddress;
}
function getStartTime(): string{

    let stakeIdLocal: string | null = localStorage.getItem('stakeId')
    let stakeId = (stakeIdLocal != null ? parseInt(stakeIdLocal): 0)

    return config.staking[stakeId].startTime || '';
}
function getEndTime(): string{

    let stakeIdLocal: string | null = localStorage.getItem('stakeId')
    let stakeId = (stakeIdLocal != null ? parseInt(stakeIdLocal): 0)

    return config.staking[stakeId].endTime || '';
}
function getRefundee(): string{

    let stakeIdLocal: string | null = localStorage.getItem('stakeId')
    let stakeId = (stakeIdLocal != null ? parseInt(stakeIdLocal): 0)

    return config.staking[stakeId].refundee || '';
}
function getUniSwapV3PoolAddress(): string{
    let stakeIdLocal: string | null = localStorage.getItem('stakeId')
    let stakeId = (stakeIdLocal != null ? parseInt(stakeIdLocal): 0)
    return config.staking[stakeId].REACT_APP_POOL_ADDRESS || "";
}


const rewardTokenAddress = getRewardTokenAddress() || '';
const uniSwapV3PoolAddress = getUniSwapV3PoolAddress() || '';
const startTime = getStartTime() || '';
const endTime = getEndTime() || '';
const refundee = getRefundee() || '';

const INCENTIVE_KEY_ABI = 'tuple(address rewardToken, address pool, uint256 startTime, uint256 endTime, address refundee)'
const incentiveKey: IncentiveKey = {
    rewardToken: rewardTokenAddress,
    pool: uniSwapV3PoolAddress,
    startTime: Number(startTime),
    endTime: Number(endTime),
    refundee: refundee
};

export function GeneralService() {
    return {
        prettyTotalStaked(amount: string) {
            let num = parseFloat(amount);
            let numStr = num.toString().slice(0, 12);
            numStr = Number(numStr).toFixed(2);
            const prettyTotal = this.formatNumber(Number(numStr));
            return prettyTotal;
        },

        formatNumber(n: number) {
            for (let i = 0; i < ranges.length; i++) {
                if (n >= ranges[i].divider) {
                    return (n / ranges[i].divider).toString() + ranges[i].suffix;
                }
            }
            return n.toString();
        },
        encodeSwashIncentiveKey(): string {
            const data: string = defaultAbiCoder.encode([INCENTIVE_KEY_ABI], [incentiveKey]);
            return data;
        }
    };
}

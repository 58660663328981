import styles from './liquidity-staked-dialog.module.css';
import {Button, Dialog, Divider, IconButton} from '@mui/material';
import closeImage from '../../images/close.svg';
import React, {useContext} from 'react';
import {useWeb3React} from '@web3-react/core';
import {LoadingContext, LoadingItem} from '../../context/loader';
import {MessageContext} from "../../context/messaging";
import MaterialTable from "material-table";
import {UniSwapV3Service} from "../../services/UniSwapV3Staker";
import LightTooltip from "../tooltip/swash-tooltip";

function LiquidityStakedDialog(props: {
    open: boolean;
    handleUnStakeDialogClose: any;
    data: any;
}) {

    const {library, account} = useWeb3React();
    const {
        setRefresh, setLoadingData, loadingData
    } = useContext(LoadingContext);

    const {
        setMessageOpen,
        setSeverity,
        setMessageText
    } = useContext(MessageContext);

    const tableRef = React.createRef();

    const doWithdraw = async (selectedRow: any) => {
        loadingData.set('LiquidityMining-withdrawLiquidity', {message: 'withdrawing...'});
        setLoadingData(new Map<string, LoadingItem>(loadingData));
        let hasError = false;
        let tx = await UniSwapV3Service(library).withdrawToken(selectedRow.tokenId, account).catch((error) => {
            console.log(error);
            setMessageOpen(true);
            setSeverity("error");
            setMessageText('Withdrawing failed.');
            hasError = true;
        });
        if (tx !== undefined) {
            await tx.wait().catch((error: any) => {
                console.log(error);
                hasError = true;
                setMessageOpen(true);
                setSeverity("error");
                setMessageText('Withdrawing failed.');
            });
        }
        loadingData.delete('LiquidityMining-withdrawLiquidity');
        setLoadingData(new Map<string, LoadingItem>(loadingData));
        if (!hasError) {
            setRefresh('LiquidityMining_' + new Date().getTime());
            setMessageOpen(true);
            setSeverity("success");
            setMessageText('Withdrawing successfully done.');
        }
    }
    const isUnStakeEnable = (selectedRow: any) => {
        return selectedRow.isStaked;
    }

    const isWithdrawEnable = (selectedRow: any) => {
        return !selectedRow.isStaked && selectedRow.numberOfStakes === 0;
    }

    const doUnStake = async (selectedRow: any) => {
        loadingData.set('LiquidityMining-unStakeLiquidity', {message: 'Unstaking...'});
        setLoadingData(new Map<string, LoadingItem>(loadingData));
        let hasError = false;
        let tx = await UniSwapV3Service(library).unStake(Number(selectedRow.tokenId)).catch((error) => {
            console.log(error);
            setMessageOpen(true);
            setSeverity("error");
            setMessageText('UnStaking failed.');
            hasError = true;
        });
        if (tx !== undefined) {
            await tx.wait().catch((error: any) => {
                console.log(error);
                hasError = true;
                setMessageOpen(true);
                setSeverity("error");
                setMessageText('UnStaking failed.');
            });
        }
        loadingData.delete('LiquidityMining-unStakeLiquidity');
        setLoadingData(new Map<string, LoadingItem>(loadingData));
        if (!hasError) {
            setRefresh('LiquidityMining_' + new Date().getTime());
            setMessageOpen(true);
            setSeverity("success");
            setMessageText('UnStaking successfully done.');
        }
    }

    // @ts-ignore
    return (
        <div className={`${styles.container}`}>
            <Dialog
                PaperProps={{
                    sx: {
                        minWidth: 850,
                    }
                }}
                open={props.open} onClose={props.handleUnStakeDialogClose}>
                <div>
                    <div className={`${styles.header}`}>
                        <div className={`${styles.headerText}`}>Your Staked Positions</div>

                        <div className={`${styles.closeImage}`}>
                            <IconButton
                                aria-label="Close"
                                onClick={() => {
                                    props.handleUnStakeDialogClose();
                                }}
                            >
                                <img alt="" src={closeImage}/>
                            </IconButton>
                        </div>
                    </div>
                    <div className={`${styles.divider}`}>
                        <Divider/>
                    </div>
                    <div className={`${styles.tableContainer}`}>
                        {/* @ts-ignore */}
                        <MaterialTable
                            tableRef={tableRef}
                            options={{
                                search: false,
                                selection: false,
                                toolbar: false,
                                paging: false,
                                sorting: false,
                                grouping: false,
                                draggable: false,
                                actionsColumnIndex: -1,

                                maxBodyHeight: 300,
                                headerStyle: {
                                    backgroundColor: '#E0F4FA',
                                    color: 'black',
                                    textAlign: 'center',
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    margin: 0
                                }
                            }}
                            columns={[
                                {title: 'Token Id', field: 'tokenId', cellStyle: {textAlign: 'center'}},
                                {
                                    title: 'Min', cellStyle: {textAlign: 'center'},
                                    render: (rowData: { title: string, min: string, max: string }) =>
                                        <LightTooltip
                                            title={rowData.title} placement="top-start">
                                            <div>{rowData.min}</div>
                                        </LightTooltip>
                                },
                                {
                                    title: 'Max', cellStyle: {textAlign: 'center'},
                                    render: (rowData: { title: string, min: string, max: string }) =>
                                        <LightTooltip
                                            title={rowData.title} placement="top-start">
                                            <div>{rowData.max}</div>
                                        </LightTooltip>
                                },
                                {title: 'Reward', field: 'rewardPretty', cellStyle: {textAlign: 'center'}},
                                {
                                    cellStyle: {paddingLeft: 0, paddingRight: 0},
                                    title: '',
                                    render: rowData => <div
                                        className={isUnStakeEnable(rowData) ? `${styles.unStakeButtonEnable}` : `${styles.unStakeButtonDisable}`}>
                                        <Button className={`${styles.unStakeButtonText}`}
                                                disabled={!isUnStakeEnable(rowData)}
                                                onClick={() => {
                                                    doUnStake(rowData).then();
                                                }
                                                }
                                                style={{textTransform: 'unset'}}>Unstake</Button></div>
                                },
                                {
                                    cellStyle: {paddingLeft: 0, paddingRight: 0},
                                    title: '',
                                    render: rowData => <div
                                        className={isWithdrawEnable(rowData) ? `${styles.withdrawButtonEnable}` : `${styles.withdrawButtonDisable}`}>
                                        <Button className={`${styles.unStakeButtonText}`}
                                                disabled={!isWithdrawEnable(rowData)}
                                                onClick={() => {
                                                    doWithdraw(rowData).then();
                                                }
                                                }
                                                style={{textTransform: 'unset'}}>Withdraw</Button></div>
                                },
                            ]}
                            data={props.data.allStakedTokens}
                        />
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

export default LiquidityStakedDialog;

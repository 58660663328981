import styles from './footer.module.css';
import telegramImage from '../../images/telegram.svg';
import twitterImage from '../../images/twitter.svg';
import IconButton from '@mui/material/IconButton';

function Footer() {
    return (
        <div className={`${styles.container}`}>
            <div className={`${styles.footerText}`}>
                Copyright © swashapp.io 2021. All rights reserved.
            </div>
            <div className={`${styles.socials}`}>
                <div>
                    <IconButton aria-label="Telegram" onClick={() => window.open("https://t.me/swashapp_group")}>
                        <img alt="" src={telegramImage}/>
                    </IconButton>
                </div>
                <div>
                    <IconButton aria-label="Twitter" onClick={() => window.open("https://twitter.com/swashapp")}>
                        <img alt="" src={twitterImage}/>
                    </IconButton>
                </div>
            </div>
        </div>
    );
}

export default Footer;

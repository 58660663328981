import styles from './vertical-label-text.module.css';

function VerticalLabelText(props: any) {
    return (
        <div>
            <div className={`${styles.container}`}>
                <div className={`${styles.label}`}>{props.label}</div>
                <div className={`${styles.text}`}>{props.text}</div>
            </div>
        </div>
    );
}

export default VerticalLabelText;

import SeedTokenClaim from '../../components/seed-token-claim/seed-token-claim';
import React from 'react';
import {useWeb3React} from "@web3-react/core";
import StrategicTokenClaim from "../../components/strategic-token-claim/strategic-token-claim";
import PresaleTokenClaim from "../../components/presale-token-claim/presale-token-claim";
import PreseedTokenClaim from "../../components/preseed-token-claim/preseed-token-claim";

function Claim() {
    const {library} = useWeb3React();
    let body;
    if(library){
        body = <><SeedTokenClaim/>
            <StrategicTokenClaim/>
            <PresaleTokenClaim/>
            <PreseedTokenClaim/>
        </>;
    } else {
        body = <>
        </>
    }
    return (
        <div className="contents">
            {body}
        </div>
    );
}

export default Claim;

import StakingDialog from '../../components/staking-dialog/staking-dialog';
import React from 'react';
import StakingOrLiquidityWrapper from "../../components/swash-staking/StakingOrLiquidityWrapper";

function Staking() {
    const [stakingDialogOpen, setStakingDialogOpen] = React.useState(false);
    const [dialogData, setDialogData] = React.useState();

    const handleStakingDialogOpen = (data: any) => {
        setDialogData(data);
        setStakingDialogOpen(true);
    };

    const handleStakingDialogClose = () => {
        setStakingDialogOpen(false);
    };

    return (
        <div>
            <StakingDialog
                open={stakingDialogOpen}
                data={dialogData}
                handleStakingDialogOpen={handleStakingDialogOpen}
                handleStakingDialogClose={handleStakingDialogClose}
            />
            <div className="contents">
                <StakingOrLiquidityWrapper/>
            </div>
        </div>
    );
}

export default Staking;

import {Contract} from '@ethersproject/contracts';
import {Web3Provider} from '@ethersproject/providers';
import {abi as nonFungiblePositionManagerAbi} from '@uniswap/v3-periphery/artifacts/contracts/NonfungiblePositionManager.sol/NonfungiblePositionManager.json'
import {abi as uniSwapV3PoolAbi} from '@uniswap/v3-core/artifacts/contracts/UniswapV3Pool.sol/UniswapV3Pool.json'
import {abi as uniSwapV3StakerAbi} from '@uniswap/v3-staker/artifacts/contracts/UniswapV3Staker.sol/UniswapV3Staker.json'
import {createClient} from 'urql';
import {formatEther} from "@ethersproject/units";
import {GeneralService} from "./GeneralService";
import {keccak256} from '@ethersproject/keccak256';
import config from "../config/config";
import {Network} from "@ethersproject/networks";
import {StakingOb} from "../components/swash-staking/swash-staking";

export function UniSwapV3Service(provider: Web3Provider) {
    const nonFungiblePositionManagerAddress = process.env.REACT_APP_NON_FUNGIBLE_POSITION_MANAGER_ADDRESS || '';
    const SWASH_SUB_GRAPH_PATH = process.env.REACT_APP_SWASH_SUB_GRAPH_PATH || '';
    const client = createClient({
        url: SWASH_SUB_GRAPH_PATH,
    });
    const nonFungiblePositionManagerContract = new Contract(
        nonFungiblePositionManagerAddress,
        nonFungiblePositionManagerAbi,
        provider?.getSigner(),
    );
    let filtered: StakingOb[];
    provider.getNetwork().then((currentNetwork:Network) =>{

        filtered = config.staking.filter(value => value.networkId === currentNetwork.chainId);

    });

    function getStakerAddress(){
        let stakeIdLocal: string | null = localStorage.getItem('stakeId')
        let stakeId = (stakeIdLocal != null ? parseInt(stakeIdLocal): 0)
        return filtered[stakeId].REACT_APP_STAKING_ADDRESS;
    }

    function getSwashTokenContract(){

        let stakeIdLocal: string | null = localStorage.getItem('stakeId')
        let stakeId = (stakeIdLocal != null ? parseInt(stakeIdLocal): 0)


        console.log('filtered[stakeId].tokenAddress')
        console.log(stakeId)
        console.log(filtered[stakeId].tokenAddress)
        return new Contract(
            filtered[stakeId].tokenAddress,
            config.erc20ABI,
            provider?.getSigner(),
        )
    }

    function getUniSwapV3PoolAddress(): string{
        let stakeIdLocal: string | null = localStorage.getItem('stakeId')
        let stakeId = (stakeIdLocal != null ? parseInt(stakeIdLocal): 0)
        return filtered[stakeId].REACT_APP_POOL_ADDRESS || "";
    }

    function getUniSwapV3StakerContract(){
        console.log('Create v3 staker contract with stakeId:');


        const stakerAddress = getStakerAddress();
        console.log(stakerAddress);
        return new Contract(
            stakerAddress,
            uniSwapV3StakerAbi,
            provider?.getSigner(),
        )
    }

    function getUniSwapV3PoolContract(){
        console.log('Create v3 pool contract with stakeId:');


        const uniSwapV3PoolAddress = getUniSwapV3PoolAddress();
        console.log(uniSwapV3PoolAddress);
        return new Contract(
            uniSwapV3PoolAddress,
            uniSwapV3PoolAbi,
            provider?.getSigner(),
        );
    }
    function getStartTime(): string{

        let stakeIdLocal: string | null = localStorage.getItem('stakeId')
        let stakeId = (stakeIdLocal != null ? parseInt(stakeIdLocal): 0)

        return filtered[stakeId].startTime || '';
    }
    function getEndTime(): string{

        let stakeIdLocal: string | null = localStorage.getItem('stakeId')
        let stakeId = (stakeIdLocal != null ? parseInt(stakeIdLocal): 0)

        return filtered[stakeId].endTime || '';
    }
    function getRefundee(): string{

        let stakeIdLocal: string | null = localStorage.getItem('stakeId')
        let stakeId = (stakeIdLocal != null ? parseInt(stakeIdLocal): 0)

        return filtered[stakeId].refundee || '';
    }

    function getRewardTokenAddress(): string{

        let stakeIdLocal: string | null = localStorage.getItem('stakeId')
        let stakeId = (stakeIdLocal != null ? parseInt(stakeIdLocal): 0)

        return filtered[stakeId].tokenAddress;
    }

    const incentiveKey = {
        rewardToken: getRewardTokenAddress(),
        pool: getUniSwapV3PoolAddress(),
        startTime: Number(getStartTime()),
        endTime: Number(getEndTime()),
        refundee: getRefundee(),
    };
    return {
        async getBalance(accountAddress: string | null) {
            let balance = await nonFungiblePositionManagerContract.balanceOf(accountAddress);
            return balance;
        },
        async gePoolData(accountAddress: string | null) {
            console.log('gePoolData')
            let token0 = await getUniSwapV3PoolContract().token0();
            let token1 = await getUniSwapV3PoolContract().token1();
            let fee = await getUniSwapV3PoolContract().fee();
            return {token0: token0, token1: token1, fee: fee};
        },

        async geNFTTokenData(accountAddress: string | null | undefined) {
            console.log('geNFTTokenData')
            console.log(accountAddress)
            let balance = await nonFungiblePositionManagerContract.balanceOf(accountAddress);
            console.log('balance')
            console.log(balance)
            let allData = [];
            let token0 = await getUniSwapV3PoolContract().token0();
            let token1 = await getUniSwapV3PoolContract().token1();
            let fee = await getUniSwapV3PoolContract().fee();
            for (let i = 0; i < Number(balance); i++) {
                let tokenId = await nonFungiblePositionManagerContract
                    .tokenOfOwnerByIndex(accountAddress, i);
                let positions = await nonFungiblePositionManagerContract
                    .positions(tokenId);
                if (Number(positions.token0.toString()) === Number(token0) &&
                    Number(positions.token1.toString()) === Number(token1) &&
                    Number(positions.fee.toString()) === Number(fee)
                ) {
                    const tokenUri: string = await nonFungiblePositionManagerContract.tokenURI(tokenId);
                    const buff = new Buffer(tokenUri.substr(tokenUri.lastIndexOf(',')), 'base64');
                    let decodedTokenURI = buff.toString('utf8');
                    let nameValue: string = JSON.parse(decodedTokenURI).name;
                    const title = nameValue.split('-')[2].trim()
                    nameValue = nameValue.substr(nameValue.lastIndexOf('-'));
                    const min = nameValue.substr(nameValue.lastIndexOf(' '), nameValue.indexOf('<') - 1);
                    const max = nameValue.substr(nameValue.lastIndexOf('>') + 1);
                    allData.push({
                        title: title,
                        min: min,
                        max: max,
                        tokenId: tokenId.toString()
                    });
                }
            }
            return allData;
        },
        async safeTransferFrom(accountAddress: string | null | undefined, tokenId: number) {
            const data = GeneralService().encodeSwashIncentiveKey();
            console.log('incentiveId data')
            console.log(data)
            return await nonFungiblePositionManagerContract['safeTransferFrom(address,address,uint256,bytes)'](accountAddress, getStakerAddress(), tokenId, data);
        },
        async geStakedData(accountAddress: string | null | undefined) {
            const graphResponse = await client.query(`query {
              depositTransferredEntities(where: { owner: "` + accountAddress + `"}) {
                id
                tokenId
                oldOwner
                owner
              }
        }`).toPromise();
            console.log('graphResponse.data')
            console.log(graphResponse)
            console.log(graphResponse.data)
            const depositTransferredEntities: Array<any> = graphResponse.data.depositTransferredEntities;
            let allData = [];
            console.log('Before getUniSwapV3PoolContract().token0()')
            let token0 = await getUniSwapV3PoolContract().token0({gasLimit:5000000,gasPrice:5000000000 });
            console.log('After getUniSwapV3PoolContract().token0()')
            let token1 = await getUniSwapV3PoolContract().token1();
            let fee = await getUniSwapV3PoolContract().fee();
            const totalRewards = await getUniSwapV3StakerContract().rewards(incentiveKey.rewardToken, accountAddress);
            const totalRewardsPretty = formatEther(totalRewards);
            const allToken:Number[]=[];

            for (let depositTransferredEntity of depositTransferredEntities) {
                const tokenId = Number(depositTransferredEntity.tokenId);
                if(allToken.indexOf(tokenId)!==-1){
                    continue;
                }
                allToken.push(tokenId);
                let positions = await nonFungiblePositionManagerContract
                    .positions(tokenId);
                if (Number(positions.token0.toString()) === Number(token0) &&
                    Number(positions.token1.toString()) === Number(token1) &&
                    Number(positions.fee.toString()) === Number(fee)
                ) {
                    let depositData = await getUniSwapV3StakerContract().deposits(Number(depositTransferredEntity.tokenId));
                    const ownerOfToken = await nonFungiblePositionManagerContract.ownerOf(tokenId);

                    if (ownerOfToken.toString() === getStakerAddress()) {
                        let incentiveId = keccak256(GeneralService().encodeSwashIncentiveKey());
                        console.log('incentiveId')
                        console.log(incentiveId)
                        const [liquidity] = await getUniSwapV3StakerContract().stakes(tokenId, incentiveId);
                        const tokenUri: string = await nonFungiblePositionManagerContract.tokenURI(tokenId);
                        const buff = new Buffer(tokenUri.substr(tokenUri.lastIndexOf(',')), 'base64');
                        let decodedTokenURI = buff.toString('utf8');
                        let nameValue: string = JSON.parse(decodedTokenURI).name;
                        const title = nameValue.split('-')[2].trim()
                        nameValue = nameValue.substr(nameValue.lastIndexOf('-'));
                        const min = nameValue.substr(nameValue.lastIndexOf(' '), nameValue.indexOf('<') - 1);
                        const max = nameValue.substr(nameValue.lastIndexOf('>') + 1);
                        let rewardNumber = 0;
                        const isStaked = Number(liquidity.toString()) > 0;
                        if (isStaked) {
                            [rewardNumber] = await getUniSwapV3StakerContract().getRewardInfo(incentiveKey, tokenId);
                        }
                        allData.push({
                            title: title,
                            reward: rewardNumber.toString(),
                            rewardPretty: Number(formatEther(rewardNumber)),
                            min: min,
                            max: max,
                            tokenId: tokenId.toString(),
                            numberOfStakes: Number(depositData.numberOfStakes),
                            isStaked: isStaked
                        });
                    }
                }
            }

            return {
                allStakedTokens: allData,
                totalRewards: totalRewards.toString(),
                totalRewardsPretty: totalRewardsPretty
            };
        },
        async claimReward(accountAddress: string | null | undefined, amount: string) {
            let stakeIdLocal: string | null = localStorage.getItem('stakeId')
            let stakeId = (stakeIdLocal != null ? parseInt(stakeIdLocal): 0)

            return await getUniSwapV3StakerContract().claimReward(
                filtered[stakeId].tokenAddress,
                accountAddress,
                amount
            );
        },
        async withdrawToken(tokenId: string, accountAddress: string | null | undefined) {
            return await getUniSwapV3StakerContract().withdrawToken(tokenId,
                accountAddress, []
            );
        },
        async unStake(tokenId: number) {
            return await getUniSwapV3StakerContract().unstakeToken(incentiveKey, tokenId);
        },
        async hasTokenForStaking(accountAddress: string | null | undefined) {
            let balance = await nonFungiblePositionManagerContract.balanceOf(accountAddress);
            let token0 = await getUniSwapV3PoolContract().token0();
            let token1 = await getUniSwapV3PoolContract().token1();
            let fee = await getUniSwapV3PoolContract().fee();
            console.log("hasTokenForStaking")
            console.log(balance)
            console.log(token0)
            console.log(token1)
            console.log(fee)
            for (let i = 0; i < Number(balance); i++) {
                let tokenId = await nonFungiblePositionManagerContract
                    .tokenOfOwnerByIndex(accountAddress, i);
                let positions = await nonFungiblePositionManagerContract
                    .positions(tokenId);
                if (Number(positions.token0.toString()) === Number(token0) &&
                    Number(positions.token1.toString()) === Number(token1) &&
                    Number(positions.fee.toString()) === Number(fee)
                ) {
                    return true;
                }
            }
            return false;
        },
        async hasStakedToken(accountAddress: string | null | undefined) {
            const graphResponse = await client.query(`query {
              depositTransferredEntities(where: { owner: "` + accountAddress + `"}) {
                id
                tokenId
                oldOwner
                owner
              }
        }`).toPromise();
            const depositTransferredEntities: Array<any> = graphResponse.data.depositTransferredEntities;
            let token0 = await getUniSwapV3PoolContract().token0();
            let token1 = await getUniSwapV3PoolContract().token1();
            let fee = await getUniSwapV3PoolContract().fee();
            for (let depositTransferredEntity of depositTransferredEntities) {
                const tokenId = Number(depositTransferredEntity.tokenId);
                let positions = await nonFungiblePositionManagerContract
                    .positions(tokenId);
                if (Number(positions.token0.toString()) === Number(token0) &&
                    Number(positions.token1.toString()) === Number(token1) &&
                    Number(positions.fee.toString()) === Number(fee)
                ) {
                    let depositData = await getUniSwapV3StakerContract().deposits(Number(depositTransferredEntity.tokenId));
                    if (Number(depositData.numberOfStakes) > 0) {
                        return true;
                    }
                }
            }
            return false;
        }
    };
}
